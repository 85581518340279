import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {LandingPageRoutingModule} from './landing-page-routing.module';
import {LandingPageComponent} from './landing-page.component';
import {TranslateModule} from "@ngx-translate/core";
import {SharedModule} from "../../shared/shared.module";


@NgModule({
  declarations: [
    LandingPageComponent
  ],
  imports: [
    CommonModule,
    LandingPageRoutingModule,
    TranslateModule,
    SharedModule
  ]
})
export class LandingPageModule {
}
