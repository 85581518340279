import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {
  HTTP_INTERCEPTORS, HttpBackend,
  HttpClient,
  HttpClientModule
} from '@angular/common/http';
import {HttpMockRequestInterceptor} from "./core/interceptors/http-mock-request.interceptor";
import {DemoComponent} from "./modules/demo/demo.component";
import {MainLayoutModule} from "./modules/main-layout/main-layout.module";
import {environment} from '../environments/environment';
import {FormsModule} from "@angular/forms";

export function HttpLoaderFactory(handler: HttpBackend): TranslateHttpLoader {
  const http = new HttpClient(handler);
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export const isMock = environment.mock;

@NgModule({
  declarations: [
    AppComponent,
    DemoComponent,
  ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpBackend]
            }
        }),
        MainLayoutModule,
        FormsModule
    ],
  providers: [
    ...isMock ? [{
      provide: HTTP_INTERCEPTORS,
      useClass: HttpMockRequestInterceptor,
      multi: true
    }] : []
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
