// example:
// https://pdfarchiv-next-backend-test.api.bmw/api/cards-documents-sop?
// Brand.in=BMW&modelSeries.in=7&documentType.in=Betriebsanleitung&language.in=en-GB

export const cardsDocumentsSopMock = [
  "1998-09-01T00:00Z[UTC]",
  "2020-11-01T00:00Z[UTC]",
  "2003-10-01T00:00Z[UTC]",
  "2013-07-01T00:00Z[UTC]",
  "2005-09-01T00:00Z[UTC]",
  "2018-11-01T00:00Z[UTC]",
  "2020-07-01T00:00Z[UTC]",
  "2015-03-01T00:00Z[UTC]",
  "2003-03-01T00:00Z[UTC]",
  "2010-03-01T00:00Z[UTC]",
  "2003-01-01T00:00Z[UTC]",
  "2012-11-01T00:00Z[UTC]",
  "2011-03-01T00:00Z[UTC]",
  "2014-07-01T00:00Z[UTC]",
  "2007-03-01T00:00Z[UTC]",
  "2001-07-01T00:00Z[UTC]",
  "2014-03-01T00:00Z[UTC]",
  "2006-09-01T00:00Z[UTC]",
  "2014-11-01T00:00Z[UTC]",
  "2012-07-01T00:00Z[UTC]",
  "2018-07-01T00:00Z[UTC]",
  "2006-03-01T00:00Z[UTC]",
  "2016-03-01T00:00Z[UTC]",
  "2019-07-01T00:00Z[UTC]",
  "2011-09-01T00:00Z[UTC]",
  "2019-11-01T00:00Z[UTC]",
  "2020-03-01T00:00Z[UTC]",
  "2016-07-01T00:00Z[UTC]",
  "2005-03-01T00:00Z[UTC]",
  "2018-03-01T00:00Z[UTC]",
  "2013-03-01T00:00Z[UTC]",
  "2002-09-01T00:00Z[UTC]",
  "2008-09-01T00:00Z[UTC]",
  "2015-11-01T00:00Z[UTC]",
  "2010-09-01T00:00Z[UTC]",
  "2017-07-01T00:00Z[UTC]",
  "2021-03-01T00:00Z[UTC]",
  "2017-03-01T00:00Z[UTC]",
  "2004-03-01T00:00Z[UTC]",
  "2017-11-01T00:00Z[UTC]",
  "2009-09-01T00:00Z[UTC]",
  "2001-11-01T00:00Z[UTC]",
  "1999-09-01T00:00Z[UTC]",
  "2015-07-01T00:00Z[UTC]",
  "2006-12-01T00:00Z[UTC]",
  "2009-03-01T00:00Z[UTC]",
  "2012-03-01T00:00Z[UTC]",
  "2002-03-01T00:00Z[UTC]",
  "2019-03-01T00:00Z[UTC]",
  "2016-11-01T00:00Z[UTC]"
];
