<div class="card"
     [ngClass]="{'disabled': isDisabled, 'selected-card': isSelected}"
     (click)="onClick()">
  <section>
    <ng-content select="[bodyContent]"></ng-content>
  </section>
  <section class="title">
    <ng-content select="[title]"></ng-content>
  </section>
  <section class="card-tags">
    <ng-content select="[tags]"></ng-content>
  </section>
</div>


