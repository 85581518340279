import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {CosyImagesModel} from "../models/cosy-images.model";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class CosyService {
  private _jsonUrl = "assets/cosy/index.json";
  private baseUrl = "https://cosystatic.bmwgroup.com/baonline/cosySec?";

  constructor(private http: HttpClient) {
  }

  getCosyImages(): Observable<any> {
    return this.http.get(this._jsonUrl).pipe(map((response: any) => this.addLinkToCosy(response)));
  }

  private addLinkToCosy(cosyImages_: CosyImagesModel[]) {
    return cosyImages_.map((image: any) => {
      if (image.modelSeries.includes('Z3')) {
        image.imageUrl = "assets/cosy/BMW_Z3.jpg";
      } else if (image.modelSeries.includes('Z8')) {
        image.imageUrl = "assets/cosy/BMW_Z8.jpg";
      } else if (image.imageUrl && image.imageUrl.includes("COSY-EU")) {
        image.imageUrl = this.baseUrl + image.imageUrl;
      } else {
        image.imageUrl = "assets/cosy/placeholder_BMW.jpg";
      }
      return image;
    });
  }
}
