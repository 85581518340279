<button type="button"
        [class]="addButtonStyle"
        [disabled]="isDisabled"
        (click)="onClick()">
  <span *ngIf="btnLabel">{{btnLabel}}</span>
  <fd-icon
    *ngIf="btnIcon"
    name="{{btnIcon}}" [size]="iconSize"
  ></fd-icon>
</button>

