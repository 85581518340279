import {Component, EventEmitter, HostBinding, Input, OnInit, Output} from '@angular/core';


type btnStyle = 'solid-style' | 'outlined-style' | 'link-style' | 'footer-style' | 'dark_mode-style' ;
type btnSize = 'btn-compact' | 'btn-default';
type btnType = 'fixed' | 'fixed-popover'| 'hug-content';
type iconPosition = 'left' | 'right';
type iconSize = 16 | 20 | 24 | 36 | 40;

@Component({
  selector: 'fd-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
  @Input() btnStyle: btnStyle = 'solid-style';
  @Input() btnSize: btnSize = 'btn-compact';
  @Input() btnType?: btnType = 'hug-content';
  @Input() btnLabel: string;
  @Input() btnIcon: string;
  @Input() iconPos: iconPosition = 'right';
  @Input() isDisabled: boolean = false;

  @Output() btnClick = new EventEmitter();

  iconSize: iconSize = 16;
  addButtonStyle: string;

  @HostBinding('class') get class() {
    return `${this.btnStyle} ${this.btnSize} ${this.btnType}`;
  }
  onClick() {
    this.btnClick.emit();
  }

  ngOnInit(): void {
    this.addButtonStyle = `${this.btnStyle} ${this.btnSize} ${this.btnType} `;
    if (this.btnIcon) {
      if (!this.btnLabel) {
        this.addButtonStyle += 'only-icon';
        this.iconSize = 24;
      }
      if (this.iconPos === 'left') {
        this.addButtonStyle += 'icon-left';
      }
      if (this.btnSize === 'btn-default' && this.btnLabel) {
        this.iconSize = 20;
      }
    }
  }

}

