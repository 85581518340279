import {ComponentRef, Injectable, Type, ViewContainerRef} from '@angular/core';
import {PopOverComponent} from "./pop-over.component";

@Injectable({ providedIn: 'root' })
export class PopOverService {
  private modal: PopOverComponent;
  private componentContainer !: ViewContainerRef;
  private componentRef: ComponentRef<any>

  setComponentContainer(container: ViewContainerRef) {
    this.componentContainer = container;
  }

  updateComponent(componentType: Type<any>){
    this.componentContainer.clear();
    this.componentRef = this.componentContainer.createComponent(componentType);
    return this.componentRef;
  }

  open(modal: PopOverComponent) {
    this.modal = modal;
    this.modal.open();
  }

  close() {
    this.componentRef.destroy();
    this.componentContainer.clear();
    this.modal.close();
  }
}
