import {
  Component,
  OnInit,
  Input,
  ViewChildren,
  QueryList,
  Output, EventEmitter
} from '@angular/core';
import {TabItemComponent} from "../tab-item/tab-item.component";

@Component({
  selector: 'fd-tab-bar',
  templateUrl: './tab-bar.component.html',
  styleUrls: ['./tab-bar.component.scss']
})
export class TabBarComponent implements OnInit {

  @Input() dataItems: any [];
  @Output() selectedTabEmitter = new EventEmitter<string>();
  tabs: TabItemComponent[] = [];
  //getting the reference of the components for easy manipulation
  @ViewChildren('itemTab') tabComponents: QueryList<TabItemComponent>

  ngOnInit() {
    this.createTabs();
  }

  createTabs() {
    if (this.dataItems) {
      this.dataItems.forEach(item => {
        let tab = new TabItemComponent();
        tab.tabTitle = item['title'];
        tab.tabSize = 'tab-default';
        this.tabs.push(tab);
      })
    }
  }

  selectTab(tab: TabItemComponent) {
    this.tabComponents.forEach(tabComponent => {
      tabComponent.active = false;
      tabComponent.changeTabState();
    });
    tab.active = true;
    tab.changeTabState();
    this.selectedTabEmitter.emit(tab.tabTitle);
  }
}
