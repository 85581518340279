import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {
  private showErrorImage = false;
  errorMessage: string;
  handleNavigationError: string;
  handleError(error: any): void {
    console.error('An error occurred:', error);
    console.log("This page doesn't seem to be available right now. Try again later.");
  }
  constructor() { }

  handle(error: any): void {
    // Customize your error handling logic here
    console.error('An error occurred:', error);
    // Additional actions such as logging, displaying error messages, etc.
    this.errorMessage = 'An error occurred. Please try again later.';
  }
  setErrorMessage(message: string) {
    this.errorMessage = message;
  }

  getErrorMessage(): string {
    return this.errorMessage;
  }
  DisplayErrorImage() {
    return this.showErrorImage;
  }
}
