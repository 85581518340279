/**
 * Codes for available translation languages
 */
import {NavbarItem} from "../../core/models/navbar-item";

export const TRANSLATION_LANGUAGE_CODES: string[] = [
  'en',
  'de'
];

export const NAVBAR_ITEMS: NavbarItem[] = [
  {id: 'home', title: 'Home', link: '/index.html', disabled: false},
  {id: 'demo', title: 'Demo Page', link: '/demo', disabled: false}
]

export const FOOTER_URLS: any = {
  'legal_disclaimer': {
    'en': 'https://www.bmwgroup.com/en/general/legal-disclaimer.html',
    'de': 'https://www.bmwgroup.com/de/general/rechtliche_hinweise.html'
  },
  'imprint': {
    'en': 'https://www.bmwgroup.com/en/general/imprint.html',
    'de': 'https://www.bmwgroup.com/de/general/impressum.html'
  },
  'data_privacy': {
    'en': 'https://www.bmwgroup.com/en/general/data_privacy.html',
    'de': 'https://www.bmwgroup.com/de/general/data_privacy.html'
  },
  'cookies': {
    'en': 'https://www.bmwgroup.com/en/general/cookies.html',
    'de': 'https://www.bmwgroup.com/de/general/cookies.html'
  },
  'contact_us': {
    'en': 'https://www.bmwgroup.com/en/general/contact.html',
    'de': 'https://www.bmwgroup.com/de/general/kontakt.html'
  }
}

export const ERROR_MESSAGES: any = {
  'not_found': {
    'title': '404\npage not found',
    'text': 'This page doesn\'t seem to be available right now.\n Try again later.'
  },
  'error': {
    'title': 'Something went wrong.',
    'text': 'We\'ll be right back for you - ready to take you towards your next driving experience.'
  }
}
