import {Component, OnInit, QueryList, ViewChildren} from '@angular/core';

import {Router} from '@angular/router';
import {environment} from 'src/environments/environment';
import {TabItemComponent} from "../tab-item/tab-item.component";
import {NavbarItem} from "../../../core/models/navbar-item";
import {NAVBAR_ITEMS} from "../../consts/consts";


interface Tab{
  link: string;
  title: string;
  size: 'tab-default' | 'tab-compact';
  isDisabled: boolean;
  active: boolean;
}

@Component({
  selector: 'fd-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']

})
export class NavbarComponent implements OnInit {
  isClose: boolean = true;
  isDisabled: boolean = false;
  selectedItem: NavbarItem;
  navItems: NavbarItem[] = [];
  tabs: Tab[] = [];

  @ViewChildren('itemNav') tabComponents: QueryList<TabItemComponent>

  constructor(private router: Router) {
    this.navItems = NAVBAR_ITEMS;
    if(!environment.showDemoRoute) {
      this.navItems = this.navItems.filter(navItem => navItem.id !== 'demo')
    }
  }

  ngOnInit() {
    if (this.navItems) {
      this.navItems.forEach(item => {
          const tab: Tab = {
            link: item.link,
            title: item.title,
            size: 'tab-default',
            isDisabled: item['disabled'],
            active: item.link === this.router.url
          };
          this.tabs.push(tab);
          if (item.link === this.router.url) {
            this.selectedItem = item;
          }
      })
    }
  }

  selectTab(tab: TabItemComponent, link: string) {
    this.tabComponents.forEach(tabComponent => {
      tabComponent.active = false;
      tabComponent.changeTabState();
    });
    tab.active = true;
    tab.changeTabState();
    this.router.navigate([link]);
  }

  redirectTo(item: NavbarItem) {
    this.selectedItem = item;
    this.router.navigate([item.link]);
    this.toggleMenu();
  }

  toggleMenu(): void {
    this.isClose = !this.isClose;
    document.body.style.overflow = this.isClose? 'inherit' : 'hidden';
  }

  toggleBackground(event: MouseEvent): void{
    if(this.isClose){
      event.stopPropagation();
    } else {
      this.toggleMenu();
    }
  }

}
