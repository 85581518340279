import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'fd-radio-btn',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss']
})
export class RadioButtonComponent {
  @Input() options: any[];
  @Input() textKey: string;
  @Input() disabledOptions: any[] = [];
  @Output() radioSelected = new EventEmitter<string>();

  @Input() selectedOption: any;

  selectRadioButton(option: string) {
    if (!this.isOptionDisabled(option)) {
      this.selectedOption = option;
      this.radioSelected.emit(this.selectedOption);
    }
  }

  isOptionDisabled(option: any): boolean {
   return this.disabledOptions.find(option_ => option_[this.textKey] === option[this.textKey]);
  }
}
