 <nav [ngClass]="{'is-open':!isClose}">
  <div class="navigation" >
    <a routerLink="/" id="logo">
      <fd-icon name="logo" [size]="52"></fd-icon>
    </a>

    <fd-tab-item class="big-screen" *ngFor="let tab of tabs;"
                 [tabTitle]="tab.title"
                 [active]="tab.active"
                 #itemNav
                 [class.disabled]="tab.isDisabled"
                 tabStyle="primary"
                 (click)="selectTab(itemNav, tab.link)"></fd-tab-item>
  </div>
  <ng-container *ngIf="isClose; else close_icon">
    <fd-button class="small-screen" btnStyle="link-style" btnIcon="menu"
               (click)="toggleMenu()"></fd-button>
  </ng-container>
  <ng-template #close_icon>
    <fd-button  class="small-screen" btnStyle="link-style" btnIcon="close"
                (click)="toggleMenu()"></fd-button>
  </ng-template>

</nav>

<div  class="small-screen menu" *ngIf="!isClose" >
  <a class="small-screen menu-item"  *ngFor="let navItem of navItems"
     [class.disable]="navItem.disabled"
     [class.selected]="navItem === selectedItem"
     (click)="redirectTo(navItem)"> {{navItem.title}}
    <fd-icon class="icon" name="arrow_right" [size]="24">
    </fd-icon>
  </a>
</div>
<div class="small-screen menu-background" *ngIf="!isClose"
     [ngClass]="{'open':!isClose, 'close':isClose }"
     (click)="toggleBackground($event)" >
</div>

