// example:
// https://pdfarchiv-next-backend-test.api.bmw/api/cards-documents?
// language.in=en-GB&Brand.in=BMW&modelSeries.in=7&documentType.in=Betriebsanleitung&sop.in=2019-07-01T00:00Z
export const cardsDocumentsMock = [
  {
    "uuid": "17199e7d-da37-4310-88eb-be604e3cad23",
    "title": "BMW 7er Limousine / BMW 7er Limousine Langversion  (G11/G12) ab  1907"
  },
  {
    "uuid": "8729058a-494f-45b3-92bb-03a60356eacb",
    "title": "BMW 7er Limousine Plug-in Hybrid / BMW 7er Limousine Langversion Plug-in Hybrid (G11PHEV/G12 PHEV) ab  1907"
  }
];
