<div class="error-massage-container">
  <div class="error-image" style="--image-url: url(../../../../assets/images/Error-image.png)"></div>
  <div class="container_overlay"></div>
    <div class="error-text-container">
      <div class="line1 fd-h3">{{ errorMessageLine1 }}</div>
      <div class="line2 body-1">{{ errorMessageLine2 }}</div>
      <div class="go_back_button_container">
        <fd-button class="fd-col-12-s fd-col-4-m1 go_back_button"
                   btnLabel="Go back home" btnType="fixed"
                   btnStyle="dark_mode-style"
                   btnSize="btn-default" (click)="redirectToHome()">
        </fd-button>
      </div>
    </div>
</div>

