import {
  Component,
  ElementRef,
  Input,
  ViewEncapsulation
} from '@angular/core';
import {fdIcons} from 'src/assets/icons';


@Component({
  selector: 'fd-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class IconComponent {

  @Input()
  set name(iconName: string) {
    //TODO: find a way to not ignore the ts
    // @ts-ignore
    this.element.nativeElement.innerHTML = fdIcons[iconName] || null;
  }

  @Input()
  set size(size: number) {
    this.element.nativeElement.style.width = `${size}px`;
    this.element.nativeElement.style.height = `${size}px`;
  }

  constructor(private element: ElementRef) {
  }


}
