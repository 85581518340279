import {
  Component,
  OnInit,
  EventEmitter, Output, Input,
} from '@angular/core';
import {CosyService} from "../../../core/services/cosy.service";
import {CosyImagesModel} from "../../../core/models/cosy-images.model";


@Component({
  selector: 'fd-card-list',
  templateUrl: './card-list.component.html',
  styleUrls: ['./card-list.component.scss']
})
export class CardListComponent implements OnInit {
  cards: CosyImagesModel[];
  @Output() selectedCardEvent = new EventEmitter<any>();

  emitCard(value: any) {
    this.selectedCardEvent.emit(value);
  }

  constructor(private cosyService: CosyService) { }

  ngOnInit() {
   this.cosyService.getCosyImages().subscribe(cards => this.cards = cards);
  }

  capitalize(title: string){
    if(title.toLowerCase().includes('series')) {
      return title.replace("SERIES", "Series");
    }
    return title;
  }
}
