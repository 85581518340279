import {NgModule} from '@angular/core';
import {MainLayoutComponent} from "./main-layout.component";
import {RouterModule, Routes} from "@angular/router";
import {LandingPageComponent} from "../landing-page/landing-page.component";
import {DemoComponent} from "../demo/demo.component";
import {ContentPageComponent} from "../content-page/content-page.component";
import {environment} from "../../../environments/environment";
import {ErrorMessageComponent} from "../../shared/components/error-message/error-message.component";
import {ERROR_MESSAGES} from "../../shared/consts/consts";

const routes: Routes = [
  {
    path: 'index.html',
    component: MainLayoutComponent,
    children: [
      {path: '', component: LandingPageComponent}
    ]
  },
  {
    path: 'demo',
    component: MainLayoutComponent,
    children: environment.showDemoRoute ?
      [{path: '', component: DemoComponent}] : [{ path: '', component: ErrorMessageComponent, data: {
          errorMessageLine1: ERROR_MESSAGES['not_found']['title'],
          errorMessageLine2: ERROR_MESSAGES['not_found']['text']
        }}]
  },
  {
    path: 'error',
    component: MainLayoutComponent,
    children: [
      {
        path: '',
        component: ErrorMessageComponent,
        data: {
          errorMessageLine1: ERROR_MESSAGES['error']['title'],
          errorMessageLine2: ERROR_MESSAGES['error']['text']
        }
      }
    ]
  }
  ,
  { path: '**',
    component: MainLayoutComponent,
    children:[
      { path: '', component: ErrorMessageComponent, data: {
          errorMessageLine1: ERROR_MESSAGES['not_found']['title'],
          errorMessageLine2: ERROR_MESSAGES['not_found']['text']
        }}]
  },
  {
    path: 'content-page/:uuid',
    component: MainLayoutComponent,
    children: [
      {path: '', component: ContentPageComponent},
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class MainLayoutRoutingModule {
}
