import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MainLayoutComponent} from './main-layout.component';
import {SharedModule} from "../../shared/shared.module";
import {MainLayoutRoutingModule} from "./main-layout-routing.module";
import {TranslateModule} from "@ngx-translate/core";
import {LandingPageModule} from "../landing-page/landing-page.module";
import {ContentPageModule} from "../content-page/content-page.module";


@NgModule({
  declarations: [
    MainLayoutComponent
  ],
  exports: [
    MainLayoutComponent,
    SharedModule,
    TranslateModule
  ],
  imports: [
    CommonModule,
    MainLayoutRoutingModule,
    SharedModule,
    TranslateModule,
    LandingPageModule,
    ContentPageModule
  ]
})
export class MainLayoutModule {
}
