import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { ButtonComponent} from "./components/button/button.component";
import { IconComponent } from './components/icon/icon.component';
import { FooterComponent } from './components/footer/footer.component';
import { TabItemComponent } from './components/tab-item/tab-item.component';
import { TabBarComponent } from './components/tab-bar/tab-bar.component';
import { PopOverComponent } from './components/pop-over/pop-over.component';
import { DatePickerItemComponent } from './components/date-picker-item/date-picker-item.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { CustomInputComponent } from "./components/custom-input/custom-input.component";
import { NavbarComponent } from './components/navbar/navbar.component';
import { FdCardComponent } from './components/fd-card/fd-card.component';
import { CardListComponent } from './components/card-list/card-list.component';
import { RadioButtonComponent } from './components/radio-button/radio-button.component';
import { ErrorHandlerService} from "../core/services/error-handler.service";
import { ErrorMessageComponent } from './components/error-message/error-message.component';

@NgModule({
  declarations: [ButtonComponent, IconComponent, FooterComponent, TabItemComponent, TabBarComponent, PopOverComponent, DatePickerItemComponent, DatePickerComponent, CustomInputComponent, NavbarComponent, FdCardComponent, CardListComponent, RadioButtonComponent, ErrorMessageComponent],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    TranslateModule,
    FormsModule,
  ],
  providers: [ErrorHandlerService],
  exports: [ButtonComponent, IconComponent, FooterComponent, TabItemComponent, TabBarComponent, PopOverComponent, DatePickerItemComponent, DatePickerComponent, CustomInputComponent, NavbarComponent, FdCardComponent, CardListComponent, RadioButtonComponent, ErrorMessageComponent],
})
export class SharedModule {
}
