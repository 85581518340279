import {Component, ComponentRef, OnInit, Type, ViewChild} from '@angular/core';
import {CardsDocumentsSopService} from "../../core/services/cards-documents-sop.service";
import {DatePickerComponent} from "../../shared/components/date-picker/date-picker.component";
import {RadioButtonComponent} from "../../shared/components/radio-button/radio-button.component";
import {CosyImagesModel} from "../../core/models/cosy-images.model";
import {Document} from "../../core/models/document.model";
import {DocumentsService} from "../../core/services/documents.service";
import {PopOverService} from "../../shared/components/pop-over/pop-over.service";
import {CardsDocumentsService} from "../../core/services/cards-documents.service";
import {CardsDocumentDisplayService} from "../../core/services/cards-document-display.service";
import {PopOverComponent} from "../../shared/components/pop-over/pop-over.component";
import {Router} from "@angular/router";

const onlyOneManualAvailable = 1;

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {

  documents: Document[];
  SOPDates: string[];
  minDate: any;
  componentType: Type<DatePickerComponent | RadioButtonComponent> = DatePickerComponent;
  selectedCard: CosyImagesModel;
  label: string;
  isManualSelection: boolean = false;
  isManualSelected: boolean = false;
  uuid: string;
  componentRef: ComponentRef<any>;
  datePickerComponent: DatePickerComponent;
  radioButtonComponent: RadioButtonComponent;

  @ViewChild(PopOverComponent) popover: PopOverComponent;

  constructor(private documentsService: DocumentsService,
              private popOverService: PopOverService,
              private sopService: CardsDocumentsSopService,
              private cardsDocumentsService: CardsDocumentsService,
              private cardDocumentDisplay: CardsDocumentDisplayService,
              private router: Router) {

  }

  ngOnInit(): void {

  }

  selectCard(card: CosyImagesModel) {
    this.selectedCard = card;
    this.sopService.getSOPByModel(card.modelSeries, card.brand).subscribe((dates: string[]) => {
      this.SOPDates = dates;
      this.popOverService.open(this.popover);
      this.setDatePickerComponent();
    },
      err =>   this.router.navigate(['/error']));
  }

  createArrayByIndex(startIndex: number, stopIndex:number) {
    let arrayByIndex = [];
    for(let i = startIndex; i <= stopIndex; i++) {
      arrayByIndex.push(i);
    }
    return arrayByIndex;
  }

  onDatePickerDateSelected(date: any){
    this.datePickerComponent.constructionMonths = date.year === this.minDate.year ?
      this.createArrayByIndex(this.minDate.month, 11) : this.createArrayByIndex(0, 11);

    const SOP = this.sopService.filterByConstructionDate(this.SOPDates,date.month, date.year);
    if(SOP) {
      this.cardsDocumentsService.getCardsDocuments(this.selectedCard.modelSeries, SOP, this.selectedCard?.brand).subscribe((documents: Document[]) => {
          this.documents = [...documents];
          this.setRadioButtonComponent();
        },
        err =>   this.router.navigate(['/error']));
    } else {
      //show error message
    }
  }

  goBack() {
    this.setDatePickerComponent();
  }

  setDatePickerComponent() {
    this.setSectionByComponent("Construction Date", DatePickerComponent);
    if(this.datePickerComponent) {
      this.datePickerComponent.selectedYearEmitter.unsubscribe();
    }
    this.componentRef = this.popOverService.updateComponent(this.componentType);
    this.datePickerComponent = this.componentRef.instance;
    if(this.SOPDates.length) {
      this.minDate = this.sopService.getMinDateFromSOP(this.SOPDates);
      this.datePickerComponent.constructionYears = this.createArrayByIndex(this.minDate.year,  new Date().getFullYear());
      this.datePickerComponent.selectedYearEmitter.subscribe((date:any) => this.onDatePickerDateSelected(date));
    } else {
      this.label = "There are no manuals available for this model."
      this.componentRef.destroy();
    }
  }
  setSectionByComponent(label: string, componentType: Type<DatePickerComponent | RadioButtonComponent>){
    this.label = label;
    this.componentType = componentType;
    this.isManualSelection = this.componentType == RadioButtonComponent;
    this.isManualSelected = false;
    this.componentRef = this.popOverService.updateComponent(this.componentType);
  }

  setRadioButtonComponent() {
    this.setSectionByComponent("Manual Selection", RadioButtonComponent);
    if(this.radioButtonComponent) {
      this.radioButtonComponent.radioSelected.unsubscribe();
    }
    this.componentRef = this.popOverService.updateComponent(this.componentType);
    this.radioButtonComponent = this.componentRef.instance;

    this.radioButtonComponent.textKey = "title";
    this.radioButtonComponent.options = this.documents;
    if(this.documents.length === onlyOneManualAvailable) {
      this.isManualSelected = true;
      this.radioButtonComponent.selectedOption = this.documents[onlyOneManualAvailable - 1];
      this.uuid = this.documents[onlyOneManualAvailable - 1].uuid;
    } else {
      this.radioButtonComponent.radioSelected.subscribe((document: Document) => {
        this.isManualSelected = true;
        this.uuid = document.uuid;
      });
    }
  }

  openPDFFile() {
    if(this.uuid) {
      this.cardDocumentDisplay.getPDFManual(this.uuid);
    }
    this.popOverService.close();
  }

}
