import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'fd-date-picker-item',
  templateUrl: './date-picker-item.component.html',
  styleUrls: ['./date-picker-item.component.scss']
})
export class DatePickerItemComponent implements OnInit {
  yearPickerItemState: any;
  @Input() isDisabled: boolean = false;
  @Input() isSelected: boolean = false;
  @Input() item: number | string;


  ngOnInit(): void {
    this.changeYearPickerItemState();
  }

  changeYearPickerItemState() {
    if (this.isDisabled) {
      this.yearPickerItemState = 'item-disabled';
    } else if (this.isSelected) {
      this.yearPickerItemState = 'item-selected';
    } else {
      this.yearPickerItemState = 'item';
    }

  }
}
