<div class="title body-1">
  <span>{{'overlay.available_manuals' | translate}}</span>
</div>
<ng-container *ngFor="let option of options">
  <span class="radio-wrapper">
    <span type="radio" class="radio-button checked"
          [class.checked]="option === selectedOption"
          [class.disabled]="isOptionDisabled(option)"
          (click)="selectRadioButton(option)">
    </span>
    <span class="radio-text body-1"
          [class.disabled]="isOptionDisabled(option)"
          (click)="selectRadioButton(option)">
      {{option[textKey]}}
    </span>
  </span>
</ng-container>
