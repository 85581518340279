export const fdIcons = {
  arrow_down: '<svg viewBox="0 0 32 32" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"M6 13.4143V10.5858L16 20.5858L26 10.5858V13.4142L16 23.4142L6 13.4143Z\" fill=\"#000000\" class=\"bmwfcol\"/><title>arrow-chevron-down_rg_48</title></svg>',
  arrow_left: '<svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="M21.4144 6L11.4143 16L21.4143 26H18.5859L8.58594 16L18.5859 6H21.4144Z" fill="#000000" class="bmwfcol"/><title>arrow-chevron-left_rg_48</title></svg>',
  arrow_right: '<svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="M10.5859 26L20.5859 16L10.5859 6H13.4144L23.4144 16L13.4143 26H10.5859Z" fill="#000000" class="bmwfcol"/><title>arrow-chevron-right_rg_48</title></svg>',
  arrow_up: '<svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="M6 21.4141V18.5857L16 8.58575L26 18.5858V21.4142L16 11.4142L6 21.4141Z" fill="#000000" class="bmwfcol"/><title>arrow-chevron-up_rg_48</title></svg>',
  calendar: '<svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="M21 4H11V2H9V4H7C6.17408 4 5.39856 4.25722 4.82789 4.82789C4.25722 5.39856 4 6.17408 4 7V25C4 25.8259 4.25722 26.6014 4.82789 27.1721C5.39856 27.7428 6.17408 28 7 28H25C25.8259 28 26.6014 27.7428 27.1721 27.1721C27.7428 26.6014 28 25.8259 28 25V7C28 6.17408 27.7428 5.39856 27.1721 4.82789C26.6014 4.25722 25.8259 4 25 4H23V2H21V4ZM6 7C6 6.58592 6.12278 6.36144 6.24211 6.24211C6.36144 6.12278 6.58592 6 7 6H25C25.4141 6 25.6386 6.12278 25.7579 6.24211C25.8772 6.36144 26 6.58592 26 7V10H6V7ZM6 12H26V25C26 25.4141 25.8772 25.6386 25.7579 25.7579C25.6386 25.8772 25.4141 26 25 26H7C6.58592 26 6.36144 25.8772 6.24211 25.7579C6.12278 25.6386 6 25.4141 6 25V12Z" fill="#000000" class="bmwfcol"/><path d="M10.6089 21.4509V22.9363C11.1819 23.0424 11.9352 23.1804 12.9325 23.1804C14.142 23.1804 14.9272 22.8939 15.4683 22.3316C15.8609 21.9178 16.0625 21.3024 16.0625 20.6764C16.0625 20.0823 15.9139 19.626 15.5744 19.2335C15.4046 19.0319 15.2455 18.9045 15.0757 18.7878C15.1606 18.6711 15.341 18.4059 15.4895 18.1725C15.7548 17.7587 15.9457 17.4191 15.9457 16.9205C15.9457 16.3581 15.7442 15.8276 15.3304 15.4457C14.8529 15.0001 14.1633 14.8197 13.081 14.8197C12.3808 14.8197 11.2349 15.0319 10.6726 15.138V16.6128C11.2137 16.5067 12.2959 16.2945 13.0492 16.2945C13.6646 16.2945 13.9935 16.3581 14.1845 16.5279C14.3436 16.6764 14.3755 16.825 14.3755 17.0478C14.3755 17.3237 14.1951 17.6207 13.9511 17.9496L13.6964 18.2998H11.4153V19.7321H12.8476C13.272 19.7321 13.6646 19.7321 13.9405 19.8701C14.3118 20.0504 14.471 20.2945 14.471 20.6764C14.471 20.9947 14.3861 21.2387 14.142 21.4297C13.8874 21.6207 13.4842 21.7056 12.9007 21.7056C12.0413 21.7056 11.2667 21.5677 10.6089 21.4509Z" fill="#000000" class="bmwfcol"/><path d="M20.8523 23V14.7773L17.2343 15.8913V17.4616L19.2608 16.8462V23H20.8523Z" fill="#000000" class="bmwfcol"/><title>calendar_rg_48</title></svg>',
  close: '<svg  viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="M15.753 17.4143L24.0459 25.7072L25.4601 24.293L17.1672 16.0001L25.4601 7.70718L24.0459 6.29297L15.753 14.5859L7.46012 6.29297L6.0459 7.70718L14.3388 16.0001L6.0459 24.293L7.46012 25.7072L15.753 17.4143Z" fill="#000000" class="bmwfcol"/><title>close_rg_48</title></svg>',
  download: '<svg  viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="M22 10.5859L17 15.5859V4H15V15.586L10 10.586V13.4144L16 19.4144L22 13.4144V10.5859Z" fill="#000000" class="bmwfcol"/><path d="M26 17V25C26 25.3106 25.896 25.4943 25.6821 25.6419C25.4158 25.8256 24.8971 26 24 26H8C7.10291 26 6.58419 25.8256 6.31792 25.6419C6.10397 25.4943 6 25.3106 6 25V17H4V25C4 25.9294 4.39603 26.7457 5.18208 27.2881C5.91581 27.7944 6.89709 28 8 28H24C25.1029 28 26.0842 27.7944 26.8179 27.2881C27.604 26.7457 28 25.9294 28 25V17H26Z" fill="#000000" class="bmwfcol"/><title>download_rg_48</title></svg>',
  external_link: '<svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="M28 4V12L26 14V7.41436L18.7072 14.7072L17.293 13.293L24.5859 6H18L20 4H28Z" fill="#000000" class="bmwfcol"/><path d="M4 8H14V10H6V26H22V18H24V28H4V8Z" fill="#000000" class="bmwfcol"/><title>external-link_rg_48</title></svg>',
  filter: '<svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="M2.75391 5H28.7539V6.91421L21.461 14.2071C20.5156 15.1526 20.1536 15.7289 19.9693 16.4109C19.7621 17.1775 19.7539 18.1444 19.7539 20V25.5744L13.8814 29H11.7539V20C11.7539 18.1444 11.7457 17.1775 11.5386 16.4109C11.3542 15.7289 10.9923 15.1526 10.0468 14.2071L2.75391 6.91421V5ZM5.66812 7L11.461 12.7929C12.5156 13.8474 13.1536 14.7211 13.4693 15.8891C13.7621 16.9725 13.7539 18.2556 13.7539 20V26.759L17.7539 24.4256V20C17.7539 18.2556 17.7457 16.9725 18.0386 15.8891C18.3542 14.7211 18.9923 13.8474 20.0468 12.7929L25.8397 7H5.66812Z" fill="#000000" class="bmwfcol"/><title>filter_rg_48</title></svg>',
  menu: '<svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="M28 17H4V15H28V17Z" fill="#000000" class="bmwfcol"/><path d="M28 9H4V7H28V9Z" fill="#000000" class="bmwfcol"/><path d="M28 25H4V23H28V25Z" fill="#000000" class="bmwfcol"/><title>menu_rg_48</title></svg>',
  search: '<svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="M14 3C10.6941 3 7.91856 4.01722 5.96789 5.96789C4.01722 7.91856 3 10.6941 3 14C3 17.3059 4.01722 20.0814 5.96789 22.0321C7.91856 23.9828 10.6941 25 14 25C16.9049 25 19.4002 24.2146 21.2863 22.7006L27.2929 28.7073L28.7071 27.293L22.7005 21.2864C24.2146 19.4004 25 16.905 25 14C25 10.6941 23.9828 7.91856 22.0321 5.96789C20.0814 4.01722 17.3059 3 14 3ZM5 14C5 11.1059 5.88278 8.88144 7.38211 7.38211C8.88144 5.88278 11.1059 5 14 5C16.8941 5 19.1186 5.88278 20.6179 7.38211C22.1172 8.88144 23 11.1059 23 14C23 16.8941 22.1172 19.1186 20.6179 20.6179C19.1186 22.1172 16.8941 23 14 23C11.1059 23 8.88144 22.1172 7.38211 20.6179C5.88278 19.1186 5 16.8941 5 14Z" fill="#000000" class="bmwfcol"/><title>search_rg_48</title></svg>',
  arrow_left_bold:'<svg viewBox="0 0 20 20"  xmlns="http://www.w3.org/2000/svg"><path d="M12.0324 3.28277L13.8008 5.05111L8.85078 10.0003L13.8008 14.9494L12.0324 16.7178L5.31578 10.0003L12.0324 3.28277Z" fill="#000000" class="bmwfcol"/><title>arrow-chevron-left_bold_rg_48</title> </svg>',
  arrow_right_bold:'<svg  viewBox="0 0 20 20"  xmlns="http://www.w3.org/2000/svg"> <path d="M7.96755 16.7172L6.19922 14.9489L11.1492 9.99973L6.19922 5.05056L7.96755 3.28223L14.6842 9.99973L7.96755 16.7172Z" fill="#262626" class="bmwfcol"/><title>arrow-chevron-right2_rg_48</title></svg>',
  arrow_up_bold: '<svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M16.7172 12.0324L14.9489 13.8008L9.99973 8.85078L5.05056 13.8008L3.28223 12.0324L9.99973 5.31578L16.7172 12.0324Z" fill="#000000" class="bmwfcol"/><title>arrow-chevron-up_bold_rg_48</title></svg>',
  arrow_down_bold: '<svg viewBox="0 0 20 20" xmlns=\"http://www.w3.org/2000/svg\"><path d="M3.28277 7.96755L5.05111 6.19922L10.0003 11.1492L14.9494 6.19922L16.7178 7.96755L10.0003 14.6842L3.28277 7.96755Z" fill=\"#000000\" class=\"bmwfcol\"/><title>arrow-chevron-down_bold_rg_48</title></svg>',
  close_bold: '<svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="M16.0001 17.4142L24.293 25.7071L25.7072 24.2929L17.4143 16L25.7072 7.70706L24.293 6.29285L16.0001 14.5858L7.70719 6.29285L6.29297 7.70706L14.5859 16L6.29297 24.2929L7.70719 25.7071L16.0001 17.4142Z" fill="#000000" class="bmwfcol"/><title>close_rg_48</title></svg>',
  logo: '<svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
    '<path class="logo_gray" d="M26 0C11.641 0 0 11.641 0 26C0 40.359 11.641 52 26 52C40.359 52 52 40.359 52 26C52 11.641 40.359 0 26 0ZM50.3093 26C50.3093 39.425 39.4265 50.3093 26 50.3093C12.5735 50.3093 1.69073 39.425 1.69073 26C1.69073 12.575 12.575 1.69073 26 1.69073C39.425 1.69073 50.3093 12.575 50.3093 26Z" fill="#6F6F6F"/>\n' +
    '<path class="logo_gray" d="M26.0002 10.5928V11.4375C34.0293 11.4375 40.5628 17.9696 40.5628 26.0002H41.4076C41.4076 17.4914 34.5089 10.5928 26.0002 10.5928ZM26.0002 40.5628C17.9711 40.5628 11.4375 34.0308 11.4375 26.0002H10.5928C10.5928 34.5089 17.4914 41.4076 26.0002 41.4076" fill="#6F6F6F"/>\n' +
    '<path class="logo_gray" d="M38.4493 15.0286C38.8021 15.4048 39.3106 16.0115 39.5962 16.38L44.8562 13.0644C44.5935 12.7231 44.175 12.2044 43.8619 11.8389L40.5309 14.026L40.3034 14.2202L40.4729 13.9745L41.9437 11.0593L40.9036 10.0263L37.9666 11.487L37.7192 11.6553L37.9162 11.4293L40.1201 8.12282C39.7322 7.7952 39.3595 7.49488 38.8845 7.13086L35.5459 12.35C35.969 12.6731 36.5264 13.1478 36.8823 13.4709L40.0254 11.851L40.227 11.7039L40.0789 11.9041L38.4493 15.0286Z" fill="#6F6F6F"/>\n' +
    '<path class="logo_gray" d="M26.5136 8.41395L27.9508 5.21663L28.0424 4.93299L28.0164 5.22876L28.1676 9.50146C28.6716 9.54848 29.1924 9.61825 29.7056 9.70319L29.4719 3.37529C28.7541 3.29642 28.0393 3.2403 27.3246 3.20996L25.9088 6.70001L25.8584 6.95938L25.808 6.70001L24.3937 3.20996C23.679 3.2403 22.9642 3.29642 22.2464 3.37529L22.0127 9.70319C22.5259 9.61825 23.0467 9.55 23.5507 9.50146L23.7019 5.22876L23.6759 4.93299L23.7675 5.21663L25.2047 8.41395H26.5136Z" fill="#6F6F6F"/>\n' +
    '<path class="logo_gray" d="M14.8893 13.457C15.7202 12.5879 16.1906 11.5838 15.3597 10.6252C14.9076 10.1049 14.1486 9.99118 13.5193 10.2475L13.4567 10.2718L13.4766 10.2172C13.5697 9.96995 13.6293 9.18123 12.9527 8.63672C12.6228 8.37129 12.2089 8.2727 11.7905 8.31517C11.01 8.39404 10.4098 8.92339 8.77562 10.7329C8.2823 11.2789 7.56448 12.1404 7.13379 12.6986L11.6225 16.9273C13.11 15.2922 13.7164 14.684 14.8893 13.457ZM9.0322 12.4225C9.93635 11.3244 10.897 10.3279 11.3323 9.93354C11.4697 9.80917 11.6194 9.6848 11.8012 9.63626C12.0959 9.55587 12.3999 9.76367 12.4686 10.064C12.5373 10.3658 12.3464 10.6525 12.1341 10.8891C11.6561 11.4245 9.90275 13.2461 9.90275 13.2461L9.0322 12.4225ZM10.7901 14.0788C10.7901 14.0788 12.4976 12.286 13.0551 11.7127C13.2765 11.4852 13.4185 11.3472 13.5713 11.2728C13.7683 11.1773 13.9836 11.1576 14.1761 11.285C14.3655 11.4109 14.4525 11.6278 14.4052 11.8431C14.3487 12.0995 14.1379 12.3437 13.9638 12.5302C13.7286 12.7805 11.7034 14.9328 11.7004 14.9358L10.7901 14.0788Z" fill="#6F6F6F"/>\n' +
    '<path class="blue" d="M26.0002 10.5928V26.0002H10.5928C10.5928 17.4853 17.4853 10.5928 26.0002 10.5928Z" fill="#0066B1" !/>\n' +
    '<path class="blue" d="M41.4074 26C41.4074 34.5149 34.5149 41.4074 26 41.4074V26H41.4074Z" fill="#0066B1"/>\n' +
    '</svg>',
};
