import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {shareReplay} from "rxjs/operators";
import {environment} from "src/environments/environment";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class CardsDocumentDisplayService {
  private baseUrl = environment.SERVER_API_URL + 'api/cards-document-display';

  constructor(private http: HttpClient, private router:Router) {
  }

  getCardsDocumentDisplay(uuid: string): Observable<any> {

    const params = new HttpParams()
      .set('uuid', uuid);

    return this.http.get(this.baseUrl, {params:params, responseType: 'text'}).pipe(shareReplay(1));
  }

  getPDFManual(uuid: string) {
    this.getCardsDocumentDisplay(uuid)
      .subscribe(pdf => {
        const target = '_blank';
        const features = 'noopener,noreferrer';
        window.open(pdf, target, features);
      }, err => {
        if (err.status === 404) {
          console.log('Not Found Error:', err);
          this.router.navigate(['/error'])
        } else {
          console.log('Other Error:', err);
          this.router.navigate(['/error'])
        }
      });
  }
}
