<div class="fd-container footer">
  <div class="fd-row">
    <div class="footer-copyright fd-col-12-s fd-col-4-m1 fd-col-6-m2"><p
      class="body-3">{{'footer.copyright' | translate }}</p></div>

    <div class="footer-buttons fd-col-12-s fd-col-8-m1 fd-col-6-m2">
      <fd-button
        btnLabel="{{'footer.legal-disclaimer' | translate }}"
        btnStyle="footer-style"
        (click)="navigateTo('legal_disclaimer')">
      </fd-button>
      <fd-button
        btnLabel="{{'footer.imprint' | translate }}"
        btnStyle="footer-style"
        (click)="navigateTo('imprint')">
      </fd-button>
      <fd-button
        btnLabel="{{'footer.data_privacy' | translate }}"
        btnStyle="footer-style"
        (click)="navigateTo('data_privacy')">
      </fd-button>
      <fd-button
        btnLabel="{{'footer.cookies' | translate }}"
        btnStyle="footer-style"
        (click)="navigateTo('cookies')">
      </fd-button>
      <fd-button
        btnLabel="{{'footer.contact_us' | translate }}"
        btnStyle="footer-style"
        (click)="navigateTo('contact_us')">
      </fd-button>
    </div>
  </div>
</div>
