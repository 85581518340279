<div class="title body-1">
  <span *ngIf="currentView === 'year'">{{'overlay.construction_year' | translate}}</span>
  <span *ngIf="currentView === 'month'">{{'overlay.construction_month' | translate}}</span>
</div>
<div class="container">

  <ng-container *ngIf="currentView === 'year';then showYearView"></ng-container>
  <ng-template #showYearView>
    <div class="header">
      <span class="range body-1">{{currentRangeText}}</span>
      <span class="icons">
        <fd-button  btnStyle="link-style" btnIcon="arrow_left" (click)="upClicked()"
               [isDisabled]="isUpDisabled"></fd-button>
        <fd-button btnStyle="link-style" btnIcon="arrow_right" (click)="downClicked()"
               [isDisabled]="isDownDisabled"></fd-button>
      </span>
    </div>
    <fd-date-picker-item *ngFor="let component of yearComponentsList" #itemYear
                         [item]="component.item"
                         [isDisabled]=component.isDisabled
                         [isSelected]=component.isSelected
                         (click)="!component.isDisabled && selectYear(itemYear)">
    </fd-date-picker-item>
  </ng-template>

  <ng-container *ngIf="currentView === 'month';then showMonthView"></ng-container>
  <ng-template #showMonthView>
    <div class="header">
      <span class="range selected body-1" (click)="openYearPicker()">{{selectedYear}}</span>
      <span class="icons">
        <fd-button btnStyle="link-style" btnIcon="arrow_left" (click)="openYearPicker()"></fd-button>
      </span>
    </div>
    <fd-date-picker-item *ngFor="let component of monthComponentList" #itemMonth
                         [item]="component.item"
                         [isDisabled]=component.isDisabled
                         [isSelected]=component.isSelected
                         (click)="!component.isDisabled && selectMonth(itemMonth)">
    </fd-date-picker-item>
  </ng-template>

</div>
