import {HttpRequest, HttpResponse} from "@angular/common/http";
import {of} from "rxjs";
import {cosyImagesMock} from "../mocks/cosy-images.mock";
import {cardsDocumentsMock} from "../mocks/cards-documents.mock";
import {cardsDocumentsSopMock} from "../mocks/cards-documents-sop.mock";
import {cardsDocumentDisplayMock} from "../mocks/cards-document-display.mock";

const getCardsDocumentDisplayMock = () => {
  return of(new HttpResponse({
    status: 404, body: cardsDocumentDisplayMock
  }))
}

const getCardsDocumentsSopMock = () => {
  return of(new HttpResponse({
    status: 200, body: cardsDocumentsSopMock
  }))
}
const getCardsDocumentsMock = () => {
  return of(new HttpResponse({
    status: 200, body: cardsDocumentsMock
  }))
}

const getCosyImages = (request: HttpRequest<any>) => {
  return of(new HttpResponse({
    status: 200, body: cosyImagesMock
  }));
};

export const selectHandler = (request: HttpRequest<any>) => {
  const url = request.urlWithParams;
  const method = request.method;

  if(url.includes('assets/cosy/index.json')){
    return getCosyImages;
  }

  switch (method) {
    case 'GET':
      if (url.includes('cards-documents-sop')) {
        return getCardsDocumentsSopMock;
      }
      else if(url.includes('cards-documents')) {
        return getCardsDocumentsMock;
      }
      else if(url.includes('cards-document-display')){
        return getCardsDocumentDisplayMock;
      }
      else {
        return null;
      }
    default:
      return null;
  }
}

