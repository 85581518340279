import {Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {FOOTER_URLS} from "../../consts/consts";
import {Router} from '@angular/router';

@Component({
  selector: 'fd-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  currentLang = this.translate.currentLang;

  constructor(private router: Router, private translate: TranslateService) {}

  navigateTo(url: string): void {
    try {
      const footerUrl = FOOTER_URLS[url][this.currentLang];
      window.open(footerUrl, '_blank');
    } catch (error){
      const url = this.router.serializeUrl(
        this.router.createUrlTree(['/error'])
      );
      window.open(url, '_blank');
    }
  }

  redirectToHome() {
    console.log('Redirecting to home...')
    this.router.navigate(['/']);
  }
}
