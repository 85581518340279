import {Component, HostBinding, Input, OnInit} from '@angular/core';

type TabState = 'active' | 'inactive';
type TabSize = 'tab-compact' | 'tab-default';
type TabStyle = 'primary' | 'secondary';

@Component({
  selector: 'fd-tab-item',
  templateUrl: './tab-item.component.html',
  styleUrls: ['./tab-item.component.scss']
})
export class TabItemComponent implements OnInit {

  state: TabState = 'inactive';
  @Input() active: boolean = false;
  @Input() tabTitle: string;
  @Input() tabStyle : TabStyle = 'secondary';
  @Input() tabSize: TabSize = 'tab-compact';
  @Input() isDisabled = false;
  addTabSize: string;

  @HostBinding('class') get class() {
    return `${this.tabSize} ${this.tabStyle} ${this.state}`;
  }

  constructor() {
  }

  ngOnInit() {
    if(this.isDisabled){

    }
    this.changeTabState();
    this.addTabSize = `${this.tabSize}`;
  }

  changeTabState() {
    this.state = this.active ? 'active' : 'inactive';
  }
}
