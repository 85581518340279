import {
  Component,
  ViewEncapsulation,
  ElementRef,
  Input,
  OnInit,
  OnDestroy,
  ViewChild,
  ViewContainerRef, ComponentRef, AfterViewInit, Type, ChangeDetectorRef
} from '@angular/core';

import { PopOverService } from './pop-over.service';
import {DatePickerComponent} from "../date-picker/date-picker.component";
import {RadioButtonComponent} from "../radio-button/radio-button.component";

@Component({
  selector: 'fd-pop-over',
  templateUrl: './pop-over.component.html',
  styleUrls: ['./pop-over.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PopOverComponent implements OnInit,AfterViewInit, OnDestroy {

  @Input() id: string;
  private element: any;

  @Input() componentType: Type<any>;
  @ViewChild('bodyContent', {read:ViewContainerRef}) bodyContent: ViewContainerRef;
  componentRef: ComponentRef<DatePickerComponent | RadioButtonComponent>;

  constructor (private popOverService: PopOverService, private el: ElementRef<any>, private cdr: ChangeDetectorRef) {
    this.element = el.nativeElement;
  }

  ngOnInit() {
    this.element.style.display = 'none';
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  loadDynamicContent(){
    if(this.componentType) {
      this.popOverService.setComponentContainer(this.bodyContent);
      this.componentRef = this.popOverService.updateComponent(this.componentType)
    }
  }

  ngOnDestroy() {
    this.element.remove();
    if(this.componentRef) {
      this.componentRef.destroy();
    }
  }

  open() {
    this.element.style.display = 'block';
    this.element.style.background = '#87457';
    document.body.classList.add('fd-pop-over-open');
    this.loadDynamicContent();
  }

  close() {
    this.element.style.display = 'none';
    document.body.classList.remove('fd-pop-over-open');
    if(this.componentRef) {
      this.componentRef.destroy();
    }
  }

  onOverlayClicked(event: MouseEvent){
    this.close();
  }
  onPopoverClicked(event: MouseEvent){
    event.stopPropagation();
  }

}
