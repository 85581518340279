<div class="input-wrapper">
  <div class="fd-h7">{{label}}</div>
  <div *ngIf="!isOpen" class="subtitle-1 selected-input">{{selectedInput}}
    <span>
     <fd-icon name="arrow_down_bold"
              [size]="16"
              (click)="open()"></fd-icon>
    </span>
  </div>
</div>
<ng-content *ngIf="isOpen"></ng-content>
