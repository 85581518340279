import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'fd-card',
  templateUrl: './fd-card.component.html',
  styleUrls: ['./fd-card.component.scss']
})
export class FdCardComponent implements OnInit {
  @Input() isDisabled: boolean = false;
  @Input() isSelected: boolean = false;

  onClick() {
    if (this.isDisabled) {
        return;
    }
  }

  ngOnInit() {

  }

}


