import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {shareReplay} from "rxjs/operators";
import {DocumentByModel} from "../models/documents-by-model";
import {DocumentSopByModel} from "../models/documents-sop-by-model";
import {environment} from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {
  private baseUrl = environment.SERVER_API_URL + 'api/documents';

  constructor(private http: HttpClient) {
  }

  getAllDocumentsByModel(): Observable<DocumentByModel[]> {
    return this.http.get<DocumentByModel[]>(this.baseUrl);
  }

  getDocumentsSopByModel(modelSeries: string, developmentCode: string): Observable<DocumentSopByModel[]> {
    const params = new HttpParams()
      .set('modelSeries', modelSeries)
      .set('developmentCode', developmentCode);

    return this.http.get<DocumentSopByModel[]>(this.baseUrl, {params: params}).pipe(shareReplay(1));
  }

  getDocumentById(uuid: any): Observable<any> {
    const params = new HttpParams()
      .set('uuid', uuid);

    return this.http.get<any>(this.baseUrl, {params: params}).pipe(shareReplay(1));
  }

}
