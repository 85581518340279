import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'custom-input',
  templateUrl: './custom-input.component.html',
  styleUrls: ['./custom-input.component.scss']
})
export class CustomInputComponent {

  @Input() label: string = '';
  @Input() selectedInput!: string | number;
  @Input() isOpen: boolean = true;
  @Output() isOpenChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  open() {
    this.isOpen = !this.isOpen;
    this.isOpenChange.emit(this.isOpen);
  }
}

