<div class="content">
  <div class="fd-container block">
    <div class="fd-h3">{{ 'landingPage.title' | translate}}</div>
    <div class="fd-h5 landing-introduction">{{'landingPage.introduction' | translate}}</div>
  </div>

  <div class="fd-container block">
    <div class="fd-row block">
      <fd-card-list (selectedCardEvent)="selectCard($event)"></fd-card-list>

      <fd-pop-over [componentType]="componentType">
        <div title>{{selectedCard?.title}}</div>
        <div label>{{label}}</div>
        <ng-container actionButtons *ngIf="isManualSelection">
            <fd-button btnStyle="outlined-style" btnIcon="arrow_left" (click)="goBack()"></fd-button>
            <fd-button style="display: flex;"
                       btnLabel="Select"
                       btnType="fixed-popover"
                       [isDisabled]="!isManualSelected"
                       (click)="openPDFFile()">
            </fd-button>
        </ng-container>
      </fd-pop-over>

    </div>
  </div>
</div>

