import {Component, OnInit, Input, ViewChildren, QueryList, Output, EventEmitter} from '@angular/core';
import {DatePickerItemComponent} from "../date-picker-item/date-picker-item.component";
import {TranslateService} from "@ngx-translate/core";

interface PickedDate  {
  year: number| string; month: number | string
}
@Component({
  selector: 'fd-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnInit {
  @Input() constructionYears?: number[];
  @Input() constructionMonths?: number[];
  @Output() selectedYearEmitter = new EventEmitter<PickedDate>();

  selectedYear: number | string;
  selectedMonth: number | string;
  size: number = 12;
  currentRangeText: string = "";
  currentRange: number[] = [];
  minYear: number = 1996;
  currentYear: number = new Date().getFullYear();
  years: number[] = [];
  months: string[] = [];
  isUpDisabled: boolean = false;
  isDownDisabled: boolean = true;
  currentView: string = 'year';
  yearComponentsList: DatePickerItemComponent[];
  monthComponentList: DatePickerItemComponent[];
  @ViewChildren('itemYear') yearItemComponents: QueryList<DatePickerItemComponent>
  @ViewChildren('itemMonth') monthItemComponents: QueryList<DatePickerItemComponent>

  constructor(private translate: TranslateService) {
    this.translate.get("components.date_picker.month_Names_Short").subscribe(months => this.months = months)
  }

  ngOnInit(): void {
    this.populateYears();
    this.createYearComponents();
  }

  populateYears() {
    for (let year = this.minYear; year <= this.currentYear; year++) {
      this.years.push(year);
    }
    this.currentRange = this.years.slice(-12);
  }

  createYearComponents() {
    this.yearComponentsList = []
    if (this.currentRange) {
      this.currentRange.forEach(item => {
        let _component = new DatePickerItemComponent();
        _component.item = item;
        if (item > this.currentYear || (this.constructionYears && !this.constructionYears.includes(item))) {
          _component.isDisabled = true;
        }
        if (item === this.selectedYear) {
          _component.isSelected = true;
        }
        this.yearComponentsList.push(_component);
      })
    }
    this.setRangeText();
  }

  createMonthComponents(): void {
    this.monthComponentList = [];
    this.months.forEach(item => {
      let _component = new DatePickerItemComponent();
      _component.item = item;
      if (this.constructionMonths && !this.constructionMonths.includes(this.months.indexOf(item))) {
        _component.isDisabled = true;
      }
      if (item === this.selectedMonth) {
        _component.isSelected = true;
      }
      this.monthComponentList.push(_component);
    })
  }

  selectYear(yearComponent: DatePickerItemComponent) {
    this.yearItemComponents.forEach(itemComponent => {
      itemComponent.isSelected = false;
      itemComponent.changeYearPickerItemState();
    })
    yearComponent.isSelected = true;
    yearComponent.changeYearPickerItemState();
    this.selectedYear = yearComponent.item;
    this.selectedYearEmitter.emit({ year: this.selectedYear, month: this.selectedMonth});
    this.openMonthPicker();
  }

  selectMonth(monthComponent: DatePickerItemComponent){
    this.monthItemComponents.forEach(itemComponent => {
      itemComponent.isSelected = false;
      itemComponent.changeYearPickerItemState();
    })
    monthComponent.isSelected = true;
    monthComponent.changeYearPickerItemState();
    if (typeof monthComponent.item === "string") {
      this.selectedMonth = this.months.indexOf(monthComponent.item);
    }
    this.selectedYearEmitter.emit({ year: this.selectedYear, month: this.selectedMonth});
  }

  downClicked() {
    const lastItem = this.currentRange.slice(-1)[0];
    if (lastItem < this.currentYear) {
      this.isUpDisabled = false;
      this.currentRange = [];
      for (let i = 0; i < this.size; i++) {
        if (lastItem + 1 + i <= this.currentYear) {
          this.currentRange.push(lastItem + 1 + i);
        } else {
          this.isDownDisabled = true;
          this.currentRange = this.years.slice(-this.size);
        }
      }
    }
    this.createYearComponents();
  }

  upClicked() {
    const firstElement = this.currentRange[0] - this.size;
    if (firstElement >= this.minYear) {
      this.isDownDisabled = false;
      this.currentRange = [];
      for (let i = 0; i < this.size; i++) {
        this.currentRange.push(firstElement + i);
      }
    } else {
      this.isUpDisabled = true;
      this.currentRange = this.years.slice(0, this.size);
    }
    this.createYearComponents();
  }

  setRangeText() {
    this.currentRangeText = `${this.currentRange[0]}-${this.currentRange.slice(-1)[0]}`;
  }

  openYearPicker(): void {
    this.currentView = 'year';
    this.createYearComponents();
  }

  openMonthPicker(): void {
    this.currentView = "month";
    this.createMonthComponents();
  }

}
