import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'fd-error-message',
  templateUrl: './error-message.component.html',
  styleUrls: ['./error-message.component.scss']
})

export class ErrorMessageComponent implements OnInit {
  errorMessageLine1: string;
  errorMessageLine2: string;

  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    this.errorMessageLine1 = this.route.snapshot.data.errorMessageLine1;
    this.errorMessageLine2 = this.route.snapshot.data.errorMessageLine2;
  }

  redirectToHome() {
    this.router.navigate(['/']);
  }
}
