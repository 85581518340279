import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {ContentPageRoutingModule} from "./content-page-routing.module";
import {ContentPageComponent} from "./content-page.component";
import {TranslateModule} from "@ngx-translate/core";
import {SharedModule} from "../../shared/shared.module";


@NgModule({
  declarations: [
    ContentPageComponent
  ],
  imports: [
    CommonModule,
    ContentPageRoutingModule,
    TranslateModule,
    SharedModule
  ]
})
export class ContentPageModule {
}
