import {Component} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {TRANSLATION_LANGUAGE_CODES} from "./shared/consts/consts";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'pdf-archiv-gui';
  modalIsOpen = false;

  constructor(private translate: TranslateService) {
  }

  ngOnInit() {
    this.translate.addLangs(TRANSLATION_LANGUAGE_CODES);
    this.translate.setDefaultLang('en');
    this.translate.use('en');
  }

  closeModal() {
    this.modalIsOpen = false;
  }
}
