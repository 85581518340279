import {Component, Type, ViewChild} from '@angular/core';
import {PopOverService} from "../../shared/components/pop-over/pop-over.service";
import {HttpClient} from "@angular/common/http";
import {CardsDocumentsSopService} from "../../core/services/cards-documents-sop.service";
import {PopOverComponent} from "../../shared/components/pop-over/pop-over.component";
import {DatePickerComponent} from "../../shared/components/date-picker/date-picker.component";
import {CardsDocumentsService} from "../../core/services/cards-documents.service";

@Component({
  selector: 'app-demo',
  templateUrl: './demo.component.html',
  styleUrls: ['./demo.component.scss']
})
export class DemoComponent {
  showDark: boolean = false;
  showOutline: boolean = false;
  showLink: boolean = false;
  showFooter: boolean = false;
  showDark_mode: boolean = false;
  codeSnippet: string;
  selectedItem: string;
  selectedYear: number;
  selectedRBOption: string;
  tabItems: any[] = [
    {id: 1, title: 'February'},
    {id: 2, title: 'June'},
    {id: 3, title: 'October'},
  ];
  tabPickerOpen = true;
  optionsRB = [{text:"Option 1"},{text:"Option 2"},{text:"Option 3"}];
  disabledOptionsRB = [{text:"Option 2"}];

  //generates an array of number of columns
  months: any;
  @ViewChild(PopOverComponent) popover: PopOverComponent;

  numberOfColumns(n: number): Array<number> {
    return Array(n);
  }

  constructor(private popOverService: PopOverService,
              private _sopService: CardsDocumentsSopService,
              private http: HttpClient) {
  }

  ngOnInit() {

  }

  showCode(type: string) {
    if (type === 'btn-solid') {
      this.showDark = !this.showDark;
      this.showOutline = false;
      this.showLink = false;
      this.showFooter = false;
      this.codeSnippet = "<fd-button type=\"btn-solid\" [isDisabled]=\"false\"></fd-button>";
    } else if (type === 'btn-outline') {
      this.showOutline = !this.showOutline;
      this.showDark = false;
      this.showLink = false;
      this.showFooter = false;
      this.codeSnippet = "<fd-button type=\"btn-outline\" [isDisabled]=\"false\"></fd-button>";
    } else if (type === 'btn-link') {
      this.showLink = !this.showLink;
      this.showOutline = false;
      this.showDark = false;
      this.showFooter = false;
      this.codeSnippet = "<fd-button type=\"btn-link\" [isDisabled]=\"false\"></fd-button>";
    } else if (type === 'btn-footer'){
      this.showFooter = !this.showFooter;
      this.showOutline = false;
      this.showDark = false;
      this.showLink = false;
      this.codeSnippet = "<fd-button type=\"btn-footer\" [isDisabled]=\"false\"></fd-button>";
    } else if (type === 'btn-dark_mode') {
      this.showDark_mode = !this.showDark_mode;
      this.showOutline = false;
      this.showDark = false;
      this.showLink = false;
      this.codeSnippet = '<fd-button type="btn-dark_mode"></fd-button>';
  }}

  getSelectedTab(value: string) {
    this.tabPickerOpen = false;
    this.selectedItem = value;

  }

  openModal() {
    this.popOverService.open(this.popover);
  }

  getSelectedYear(value: any) {
    if(value.month && value.year)
    this._sopService.getDate(value.month, value.year, "7")
      .subscribe( data => console.log(data));
  }

  getSelectedRBOption(value: any) {
    this.selectedRBOption = value.text;
  }

}
