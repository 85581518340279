<div class="fd-h1 fd-container block">
 FUSION DESIGN SYSTEM
</div>
<div class="fd-container block">
  <div class="fd-h3 mb-6">BUTTONS</div>
  <div class="fd-row block" style="align-items: center">
    <fd-button btnLabel="Label" btnStyle="solid-style"></fd-button>
    <fd-button btnLabel="Label" btnStyle="solid-style" [isDisabled]="true"></fd-button>
    <fd-button btnLabel="Label" btnStyle="solid-style" btnIcon="arrow_right_bold"></fd-button>
    <fd-button btnLabel="Label" btnStyle="solid-style" btnSize="btn-default"></fd-button>
    <fd-button btnLabel="Label" btnStyle="solid-style" btnSize="btn-default" [isDisabled]="true"></fd-button>
    <fd-button btnLabel="Label" btnStyle="solid-style" btnIcon="arrow_left_bold" iconPos="left"
               btnSize="btn-default"></fd-button>
  </div>

  <div class="fd-row block">
    <fd-button class="fd-col-12-s fd-col-4-m1" btnLabel="Label" btnType="fixed" btnStyle="solid-style" ></fd-button>
  </div>
  <div class="fd-row block">
    <fd-button class="fd-col-12-s fd-col-4-m1" btnLabel="Label and a very long text" btnType="fixed" btnStyle="solid-style" btnSize="btn-default"></fd-button>
  </div>

  <div class="fd-row block">
    <fd-button btnLabel="Label" btnType="fixed-popover" btnStyle="solid-style"></fd-button>
  </div>
  <div class="fd-row block">
    <fd-button btnLabel="Label and a very long text" btnType="fixed-popover" btnStyle="solid-style" btnSize="btn-default"></fd-button>
  </div>

  <div class="fd-row block" style="align-items: center">
    <fd-button btnLabel="Label" btnStyle="outlined-style"></fd-button>
    <fd-button btnLabel="Label" btnStyle="outlined-style" [isDisabled]="true"></fd-button>
    <fd-button btnLabel="Label" btnStyle="outlined-style" btnIcon="arrow_right_bold"></fd-button>
    <fd-button btnLabel="Label" btnStyle="outlined-style" btnSize="btn-default"></fd-button>
    <fd-button btnLabel="Label" btnStyle="outlined-style" btnSize="btn-default" [isDisabled]="true"></fd-button>
    <fd-button btnLabel="Label" btnStyle="outlined-style" btnIcon="arrow_left_bold" iconPos="left"
               btnSize="btn-default"></fd-button>

  </div>
  <div class="fd-row block" style="align-items: center">
    <fd-button btnLabel="Label" btnStyle="link-style" btnIcon="arrow_right_bold"></fd-button>
    <fd-button btnLabel="Label" btnStyle="link-style" btnIcon="calendar" iconPos="left"
               [isDisabled]="true"></fd-button>
    <fd-button btnLabel="Label" btnStyle="link-style" btnIcon="arrow_right_bold" btnSize="btn-default"></fd-button>
    <fd-button btnLabel="Label" btnStyle="link-style" btnIcon="arrow_right_bold" btnSize="btn-default"
               [isDisabled]="true"></fd-button>
    <fd-button btnStyle="link-style" btnIcon="menu"></fd-button>
    <fd-button btnStyle="link-style" btnIcon="menu" [isDisabled]="true"></fd-button>
    <fd-button btnStyle="link-style" btnIcon="menu" btnSize="btn-default"></fd-button>
    <fd-button btnStyle="link-style" btnIcon="menu" btnSize="btn-default" [isDisabled]="true"></fd-button>
  </div>
  <div class="fd-row block" style="align-items: center">
    <fd-button btnStyle="solid-style" btnIcon="menu"></fd-button>
    <fd-button btnStyle="solid-style" btnIcon="menu" [isDisabled]="true"></fd-button>
    <fd-button btnStyle="solid-style" btnIcon="menu" btnSize="btn-default"></fd-button>
    <fd-button btnStyle="solid-style" btnIcon="menu" btnSize="btn-default" [isDisabled]="true"></fd-button>
  </div>
  <div class="fd-row block" style="align-items: center">
    <fd-button btnStyle="outlined-style" btnIcon="menu"></fd-button>
    <fd-button btnStyle="outlined-style" btnIcon="menu" [isDisabled]="true"></fd-button>
    <fd-button btnStyle="outlined-style" btnIcon="menu" btnSize="btn-default"></fd-button>
    <fd-button btnStyle="outlined-style" btnIcon="menu" btnSize="btn-default" [isDisabled]="true"></fd-button>
  </div>
</div>

<div class="dark-container">
  <div class="fd-container block">
    <div class="fd-row block" style="align-items: center">
      <fd-button btnLabel="Label" btnStyle="dark_mode-style"></fd-button>
      <fd-button btnLabel="Label" btnStyle="dark_mode-style" [isDisabled]="true"></fd-button>
      <fd-button btnLabel="Label" btnStyle="dark_mode-style" btnIcon="arrow_right_bold"></fd-button>
      <fd-button btnLabel="Label" btnStyle="dark_mode-style" btnSize="btn-default"></fd-button>
      <fd-button btnLabel="Label" btnStyle="dark_mode-style" btnSize="btn-default" [isDisabled]="true"></fd-button>
      <fd-button btnLabel="Label" btnStyle="dark_mode-style" btnIcon="arrow_right_bold" btnSize="btn-default"></fd-button>
    </div>

    <div class="fd-row block">
      <fd-button class="fd-col-12-s fd-col-4-m1" btnLabel="Label" btnType="fixed" btnStyle="dark_mode-style"></fd-button>
    </div>
    <div class="fd-row block">
      <fd-button class="fd-col-12-s fd-col-4-m1" btnLabel="Label and a very long text" btnType="fixed" btnStyle="dark_mode-style" btnSize="btn-default"></fd-button>
    </div>

    <div class="fd-row block" >
      <fd-button btnLabel="Label" btnType="fixed-popover" btnStyle="dark_mode-style" ></fd-button>
    </div>
    <div class="fd-row block">
      <fd-button btnLabel="Label and a very long text" btnType="fixed-popover" btnStyle="dark_mode-style" btnSize="btn-default"></fd-button>
    </div>
  </div>
</div>



<div class="fd-container block">
  <div class="fd-h7 mb-6">API</div>
  <table class="body-1 fd-col-s-12 btn-api-table">
    <thead>
    <tr class="subtitle-1">
      <td>Name</td>
      <td>Type</td>
      <td>Default</td>
      <td>Description</td>
    </tr>
    </thead>
    <tbody>
    <tr>
      <td>btnStyle</td>
      <td>'solid-style' | 'outlined-style' | 'link-style' | 'footer-style'| 'secondary-style' | 'tertiary-style'</td>
      <td>'solid-style'</td>
      <td>Controls the appearance of the Button</td>
    </tr>
    <tr>
      <td>btnSize</td>
      <td>'btn-compact' | 'btn-default'</td>
      <td>'btn-compact'</td>
      <td>Controls the size of the Button</td>
    </tr>
    <tr>
      <td>btnLabel</td>
      <td>string</td>
      <td>'null'</td>
      <td>The text displayed in the button.</td>
    </tr>
    <tr>
      <td>btnIcon</td>
      <td>string</td>
      <td>'null'</td>
      <td>Takes any icon from the names of the icons in the table bellow.</td>
    </tr>
    <tr>
      <td>iconPos</td>
      <td>'left' | 'right'</td>
      <td>'right'</td>
      <td>Controls the position of the icon.</td>
    </tr>
    <tr>
      <td>isDisabled</td>
      <td>boolean</td>
      <td>false</td>
      <td>Set it to true to disable the button.</td>
    </tr>
    </tbody>
  </table>
</div>

<!--grid-->
<div class="fd-container block">
  <div class="fd-h3 mb-6">THE GRID SYSTEM</div>
  <p class="body-1 mb-6">Our grid system uses the pattern col- device - number of columns (eg. col-s-12).
    Check the <strong>table</strong> bellow for devices and their corresponding breakpoints.</p>
  <table class="body-1 fd-col-s-12">
    <thead>
    <tr class="subtitle-1">
      <td></td>
      <td>Viewport</td>
      <td>Margin</td>
      <td>Gutter</td>
      <td>Columns</td>
      <td>Breakpoint</td>
      <td>Container</td>
    </tr>
    </thead>
    <tbody>
    <tr>
      <td class="subtitle-1">S</td>
      <td>360 x 640</td>
      <td>24px</td>
      <td>16px</td>
      <td>12</td>
      <td>0 - 767</td>
      <td>Fluid</td>
    </tr>
    <tr>
      <td class="subtitle-1">M 1</td>
      <td>768 x 1024</td>
      <td>48px</td>
      <td>24px</td>
      <td>12</td>
      <td>768 - 1023</td>
      <td>Fluid</td>
    </tr>
    <tr>
      <td class="subtitle-1">M 2</td>
      <td>1024 x 768</td>
      <td>48px</td>
      <td>24px</td>
      <td>12</td>
      <td>1024 - 1279</td>
      <td>Fluid</td>
    </tr>
    <tr>
      <td class="subtitle-1">L</td>
      <td>1280 x 720</td>
      <td>96px</td>
      <td>24px</td>
      <td>12</td>
      <td>1280 - 1919</td>
      <td>Fluid</td>
    </tr>
    <tr>
      <td class="subtitle-1">XL</td>
      <td>1920 x 1080</td>
      <td>auto</td>
      <td>24px</td>
      <td>12</td>
      <td>1920 +</td>
      <td>Fixed</td>
    </tr>
    </tbody>
  </table>
  <div class="fd-row row-demo">
    <div class="column-demo fd-col-1-s fd-col-1-m1 fd-col-1-m2 fd-col-1-l fd-col-1-xl"
         *ngFor="let i of numberOfColumns(12)"></div>
  </div>
</div>

<div class="fd-container block">
  <div class="fd-h3 mb-6">Typography</div><!--Change this -->


 <div class="fd-h0">This is h0 uppercase on mobile</div>

  <div class="fd-h1">This is h1 uppercase on mobile</div>

  <div class="fd-h2">This is h2 uppercase on mobile</div>
  <div class="fd-h3">This is h3 uppercase on mobile</div>
  <div class="fd-h4">This is h4 uppercase on mobile</div>
  <div class="fd-h5">This is h5 uppercase on mobile</div>
  <div class="fd-h6">This is h6 lowercase on mobile</div>
  <div class="fd-h7">This is h7 lowercase on mobile</div>
  <div class="fd-h8">This is h8 lowercase on mobile</div>

  <div class="subtitle-0">This is subtitle-0</div>
  <div class="subtitle-1">This is subtitle-1</div>
  <div class="subtitle-2">This is subtitle-2</div>
  <div class="subtitle-3">This is subtitle-3</div>

  <div class="body-0">This is body-0</div>
  <div class="body-1">This is body-1</div>
  <div class="body-2">This is body-2</div>
  <div class="body-3">This is body-3</div>

  <div class="link-0">This is link-0</div>
  <div class="link-1">This is link-1</div>
  <div class="link-2">This is link-2</div>
  <div class="link-3">This is link-3</div>

  <div class="label-1">This is label-1</div>
  <div class="label-2">This is label-2</div>

  <div class="eyebrow-1">This is eyebrow-1</div>
  <div class="eyebrow-2">This is eyebrow-2</div>
  <div class="eyebrow-3">This is eyebrow-3</div>

  <div class="eyebrow-1-bold">This is eyebrow-1-bold</div>
  <div class="eyebrow-2-bold">This is eyebrow-2-bold</div>
</div>


<!--icons-->
<div class="fd-container block">
  <div class="fd-h3 mb-6">ICONS</div>

    <table class="icon-table body-1 fd-col-s-12">
      <thead>
      <tr>
        <td></td>
        <td>"16"</td>
        <td>"20"</td>
        <td>"24"</td>
        <td>"36"</td>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>Arrow left</td>
        <td>
          <fd-icon name="arrow_left" [size]="16"></fd-icon>
        </td>
        <td>
          <fd-icon name="arrow_left" [size]="20"></fd-icon>
        </td>
        <td>
          <fd-icon name="arrow_left" [size]="24"></fd-icon>
        </td>
        <td>
          <fd-icon name="arrow_left" [size]="36"></fd-icon>
        </td>
      </tr>
      <tr>
        <td>Arrow left bold</td>
        <td>
          <fd-icon name="arrow_left_bold" [size]="16"></fd-icon>
        </td>
        <td>
          <fd-icon name="arrow_left_bold" [size]="20"></fd-icon>
        </td>
        <td>
          <fd-icon name="arrow_left_bold" [size]="24"></fd-icon>
        </td>
        <td>
          <fd-icon name="arrow_left_bold" [size]="36"></fd-icon>
        </td>
      </tr>
      <tr>
        <td>Arrow down</td>
        <td>
          <fd-icon name="arrow_down" [size]="16"></fd-icon>
        </td>
        <td>
          <fd-icon name="arrow_down" [size]="20"></fd-icon>
        </td>
        <td>
          <fd-icon name="arrow_down" [size]="24"></fd-icon>
        </td>
        <td>
          <fd-icon name="arrow_down" [size]="36"></fd-icon>
        </td>
      </tr>
      <tr>
        <td>Arrow down bold</td>
        <td>
          <fd-icon name="arrow_down_bold" [size]="16"></fd-icon>
        </td>
        <td>
          <fd-icon name="arrow_down_bold" [size]="20"></fd-icon>
        </td>
        <td>
          <fd-icon name="arrow_down_bold" [size]="24"></fd-icon>
        </td>
        <td>
          <fd-icon name="arrow_down_bold" [size]="36"></fd-icon>
        </td>
      </tr>
      <tr>
        <td>Arrow up</td>
        <td>
          <fd-icon name="arrow_up" [size]="16"></fd-icon>
        </td>
        <td>
          <fd-icon name="arrow_up" [size]="20"></fd-icon>
        </td>
        <td>
          <fd-icon name="arrow_up" [size]="24"></fd-icon>
        </td>
        <td>
          <fd-icon name="arrow_up" [size]="36"></fd-icon>
        </td>
      </tr>
      <tr>
        <td>Arrow up bold</td>
        <td>
          <fd-icon name="arrow_up_bold" [size]="16"></fd-icon>
        </td>
        <td>
          <fd-icon name="arrow_up_bold" [size]="20"></fd-icon>
        </td>
        <td>
          <fd-icon name="arrow_up_bold" [size]="24"></fd-icon>
        </td>
        <td>
          <fd-icon name="arrow_up_bold" [size]="36"></fd-icon>
        </td>
      </tr>
      <tr>
        <td>Arrow right</td>
        <td>
          <fd-icon name="arrow_right" [size]="16"></fd-icon>
        </td>
        <td>
          <fd-icon name="arrow_right" [size]="20"></fd-icon>
        </td>
        <td>
          <fd-icon name="arrow_right" [size]="24"></fd-icon>
        </td>
        <td>
          <fd-icon name="arrow_right" [size]="36"></fd-icon>
        </td>
      </tr>
      <tr>
        <td>Arrow right bold</td>
        <td>
          <fd-icon name="arrow_right_bold" [size]="16"></fd-icon>
        </td>
        <td>
          <fd-icon name="arrow_right_bold" [size]="20"></fd-icon>
        </td>
        <td>
          <fd-icon name="arrow_right_bold" [size]="24"></fd-icon>
        </td>
        <td>
          <fd-icon name="arrow_right_bold" [size]="36"></fd-icon>
        </td>
      </tr>
      <tr>
        <td>Download</td>
        <td>
          <fd-icon name="download" [size]="16"></fd-icon>
        </td>
        <td>
          <fd-icon name="download" [size]="20"></fd-icon>
        </td>
        <td>
          <fd-icon name="download" [size]="24"></fd-icon>
        </td>
        <td>
          <fd-icon name="download" [size]="36"></fd-icon>
        </td>
      </tr>
      <tr>
        <td>Close</td>
        <td>
          <fd-icon name="close" [size]="16"></fd-icon>
        </td>
        <td>
          <fd-icon name="close" [size]="20"></fd-icon>
        </td>
        <td>
          <fd-icon name="close" [size]="24"></fd-icon>
        </td>
        <td>
          <fd-icon name="close" [size]="36"></fd-icon>
        </td>
      </tr>
      <tr>
        <td>Calendar</td>
        <td>
          <fd-icon name="calendar" [size]="16"></fd-icon>
        </td>
        <td>
          <fd-icon name="calendar" [size]="20"></fd-icon>
        </td>
        <td>
          <fd-icon name="calendar" [size]="24"></fd-icon>
        </td>
        <td>
          <fd-icon name="calendar" [size]="36"></fd-icon>
        </td>
      </tr>
      <tr>
        <td>Filter</td>
        <td>
          <fd-icon name="filter" [size]="16"></fd-icon>
        </td>
        <td>
          <fd-icon name="filter" [size]="20"></fd-icon>
        </td>
        <td>
          <fd-icon name="filter" [size]="24"></fd-icon>
        </td>
        <td>
          <fd-icon name="filter" [size]="36"></fd-icon>
        </td>
      </tr>
      <tr>
        <td>Menu</td>
        <td>
          <fd-icon name="menu" [size]="16"></fd-icon>
        </td>
        <td>
          <fd-icon name="menu" [size]="20"></fd-icon>
        </td>
        <td>
          <fd-icon name="menu" [size]="24"></fd-icon>
        </td>
        <td>
          <fd-icon name="menu" [size]="36"></fd-icon>
        </td>
      </tr>
      <tr>
        <td>Search</td>
        <td>
          <fd-icon name="search" [size]="16"></fd-icon>
        </td>
        <td>
          <fd-icon name="search" [size]="20"></fd-icon>
        </td>
        <td>
          <fd-icon name="search" [size]="24"></fd-icon>
        </td>
        <td>
          <fd-icon name="search" [size]="36"></fd-icon>
        </td>
      </tr>
      <tr>
        <td>External link</td>
        <td>
          <fd-icon name="external_link" [size]="16"></fd-icon>
        </td>
        <td>
          <fd-icon name="external_link" [size]="20"></fd-icon>
        </td>
        <td>
          <fd-icon name="external_link" [size]="24"></fd-icon>
        </td>
        <td>
          <fd-icon name="external_link" [size]="36"></fd-icon>
        </td>
      </tr>
      </tbody>
    </table>



</div>

<!--Tab Bar-->
<div class="fd-container block">
  <div class="fd-h3 mb-6">TAB BAR</div>
  <div class="fd-row block">
    <fd-tab-bar [dataItems]="tabItems" (selectedTabEmitter)="getSelectedTab($event)"></fd-tab-bar>
  </div>
  <div *ngIf="selectedItem" class="subtitle-3">
    The selected Tab is <span class="selected-tab">{{selectedItem}}</span>
  </div>
</div>


<!--Datepicker-->

<div class="fd-container block">
  <div class="fd-h3 mb-6">DATE PICKER</div>
  <div class="fd-row block">
    <fd-date-picker (selectedYearEmitter)="getSelectedYear($event)"></fd-date-picker>
  </div>
</div>

<!--Pop over-->

<div class="fd-container block">
  <div class="fd-h3 mb-6">POPOVER</div>
  <div class="fd-row block">
    <fd-button
      btnLabel="Open Pop-over"
      [isDisabled]="false" (click)="openModal()">
    </fd-button>
  </div>

  <fd-pop-over>
    <div title>Car Information</div>
    <div label>Construction Date</div>

    <fd-date-picker bodyContent
                  (selectedYearEmitter)="getSelectedYear($event)">
    </fd-date-picker>

    <ng-container actionButtons >
      <fd-button  btnLabel="Show Recent" btnStyle="outlined-style"></fd-button>
      <fd-button  btnLabel="Apply" btnType="fixed-popover" btnStyle="solid-style" ></fd-button>
    </ng-container>

  </fd-pop-over>

</div>

<!--Card-->
<div class="fd-container block">
  <div class="fd-h3 mb-6">CARD</div>
  <div class="fd-row block">

    <fd-card-list></fd-card-list>
  </div>
</div>

<!--Radio-Button-->
<div class="fd-container block">
  <div class="fd-h3 mb-6">RADIO-BUTTON</div>
  <div class="fd-row block">
    <fd-radio-btn [options]="optionsRB"
                  textKey="text"
                  [disabledOptions]="disabledOptionsRB"
                  (radioSelected)="getSelectedRBOption($event)"
    ></fd-radio-btn>
  </div>
  <div *ngIf="selectedRBOption" class="subtitle-3">
    The selected label is <span class="selected-tab">{{selectedRBOption}}</span>
  </div>
</div>
