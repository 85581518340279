import {Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {selectHandler} from '../helpers/mock.helper';

@Injectable()
export class HttpMockRequestInterceptor implements HttpInterceptor {

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const mockEndpointHandler = selectHandler(request);
    return mockEndpointHandler ? mockEndpointHandler(request) : next.handle(request);
  }
}
