import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map, shareReplay} from 'rxjs/operators';
import {environment} from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class CardsDocumentsSopService {
  private baseUrl = environment.SERVER_API_URL + 'api/cards-documents-sop?';
  SOP: Date[];

  constructor(private http: HttpClient) {
  }

  getSOPByModel(modelSeries: string,
                brand: string = 'BMW',
                documentType: string = "Betriebsanleitung",
                language: string = "en-GB"): Observable<any>{

    const params = new HttpParams()
      .set('Brand.in', brand)
      .set('documentType.in', documentType)
      .set('language.in', language)
      .set('modelSeries.in', modelSeries);

    return this.http.get<any[]>(this.baseUrl, {params: params}).pipe(shareReplay(1));

  }

  getDate(targetMonth: string | number, targetYear: string | number,
           brand = 'BMW', model = '7'): Observable<string | null> {
    return this.http.get<any>(this.baseUrl + `Brand.in=${brand}&modelSeries.in=${model}`).pipe(
      map((dates: string[]) => this.filterByConstructionDate(dates, targetMonth, targetYear))
    );
  }

  public getMinDateFromSOP(sop: string[]) {
    if(sop.length) {
      this.SOP = sop.map((date: any) => new Date(date.slice(0,-5)));
      let min = this.SOP.reduce((firstDate, secondDate) => { return firstDate < secondDate ? firstDate : secondDate });
      return {year: min.getFullYear(), month: min.getMonth()};
    }
    return null;
  }

  // Filter SOPs by construction date
  public filterByConstructionDate(sop: string[], month: string | number, year:  string | number): string | null {
    const constructionDate: any = Date.UTC(Number(year), Number(month));
    this.SOP = sop.map((date: any) => new Date(date.slice(0,-5)));
    let closestDate: Date | null = null;
    let closestDifference = Infinity;

    for (const date of this.SOP) {
      const difference = constructionDate - date.getTime();

      if (difference >= 0 && difference < closestDifference) {
        closestDifference = difference;
        closestDate = date;
      }
    }
    const dateString = closestDate?.toISOString();
    // Remove seconds and milliseconds
    const closestDateWithoutSec = dateString?.slice(0, dateString.indexOf(':', dateString.indexOf(':') + 1));
    return closestDateWithoutSec ? closestDateWithoutSec + 'Z': null;
  }
}

