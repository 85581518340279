export const cosyImagesMock = [
  {
    "brand": "BMW",
    "modelSeries": "1",
    "title": "BMW 1 SERIES",
    "imageUrl": "COSY-EU-100-7331gPd9TZ7d5yKlHS9TUz6LlEJei88eEgpnJc0WHvQF%25c5eqKJ4uQ7bswTHu1Q53EjAxKayVzbYCjVhuLB9u4RKwcJehoIjAiLLyTBzchtN9%25eqKNuL7TVswTsD0RArxlTGix2xdxJyw178z89LtECUkUiw7slGAGKICrXpFkr%25lZQ6KABxXRaYWFLVQ5nmPKIFagOybWBCnvIT9alSO2B3inXvIjedwOEXBDMztIc0eqhk7B%25nMLoACec%25hJHFliELou%25KXwrDHSfWQtZu%25V1Pa7qGfNEbnCxM10s9Ol8ZE4riIXU7scZwBQ1drxRte2oNZ857Mj61RUgChDYF5GvloqmWgp2XHLbsv6jQ%25dJ82YDafzo8jmqn1kHDDyLOEAGGqTJIsFtLL3uBrKLtJdSeZS42uzVMRVvjSkNh5NXOVA0og09lNF4Hv4jB0Kc%252cFa4WsaBuCLB94Pdp5GaUujDqgXAE7xqNQITkHN"
  },
  {
    "brand": "BMW",
    "modelSeries": "2",
    "title" : "BMW 2 SERIES",
    "imageUrl":  "COSY-EU-100-7331gPd9TZ7d5yKlHS9TUz6LlEJeiPlZhgpnJc0WHvQFVc5eqKJ4uQ7bswTlu1Q53EjAxKayVzbYCjVhuLB9u4RKwcJehoIjAiLLyTBzchtN5aeqKNuL7TVswTsyV7IrxlTGix2xdxTsw178ziG8tECUkUp37slGAzOECrXpFkYulZQ6KAqSXRaYWFZEQ5nmPKIFagOybWBynvIT9PecO2B3iEoMIjedwsQhBDMztrAeeqhk7ZFGMLoACe1AhJHFlMIxou%25KXhBJHSfWQoSD%25V1PaHVmfNEbn%25BA10s9OfJEE4riI1D0scZwBEqwrxRtes3yZ857Mrd0RUgChZVQ5GvloRV5gp2XHBLGv6jQ%25e682YDafMSUjmqn1hVYDyLOEoZuqTJIsHkIL3uBr%25AUJdSeZfFpuzVMR1L7SkNh5EJhVA0ogsu5NF4HvUDd0Kc%252GmF4WxfjpiScP81D6wOxbUEqYtB89GsLmMhUiprJymhGw6ZuTAnptYRS3n367m5VnJbYCygNO99mlTv0IfyyX324BDZTQdjceq63aJ1Z6oGZI3ntKNF1A6x8U0%25lLhzUyfriC2y"
  },
  {
    "brand": "BMW",
    "modelSeries": "3",
    "title" : "BMW 3 SERIES",
    "imageUrl": "COSY-EU-100-7331gPd9TZ7d5yKlHS9TUz6LlEJeiZlmmgpnJc0WHvQFVc5eqKJ4uQ7bswTlu1Q53EjAxKayVzbYCjVhuLB9u4RKwcJehoIjAiLLyTBzchtDcQeqKtyTLcCXRNHCOv5F36l7ifGRs1dNZnTNn21quO2kNqy2o242DVTQdjcjqw3azDx4LKdnkq8cFszOALUtPKkIFJG7TWABKupCVJFeWS6AeaKMPVYFMdWhbNmK3LPo90yWIbbHi4TPa49%25wc3bvMiftxd9BGw178ziBwtECUkyEH7slGATO%25CrXpF3gclZQ6Kdv6XRaYWz2HQ5nmPkjfagOybAX0nvIT9FQTO2B3iKa9IjedwHC5BDMzt%25sjeqhk7fSnMLoAC1VphJHFlENYou%25KXsyTHSfWQrsb%25V1PaZGmfNEbnRjp10s9O5ZQE4riIg63scZwB6vdrxRteYOtZ857MmNNRUgChyK55GvloTQsgp2XH2GWv6OWirkEiyvYcJMLWqr%25f1hFUnzj15K908l5"
  },
  {
    "brand": "BMW",
    "modelSeries": "4",
    "title" : "BMW 4 SERIES",
    "imageUrl": "COSY-EU-100-7331gPd9TZ7d5yKlHS9TUz6LlEJeiPlmbgpnJc0WHvQFVc5eqKJ4uQ7bswTlu1Q53EjAxKayVzbYCjVhuLB9u4RKwcJehoIjAiLLyTBzchtNfUeqKNuL7TVswTsyVgjrxlTGix2xdxJyw178z89LtECUkUF27slGAzsHCrXpFknWlZQ6KAqSXRaYWFZAQ5nmPKIFagOybWBonvIT91heO2B3inXvIjedwOjXBDMztIlmeqhk7BrDMLoACe8chJHFlMUjou%25KXh0yHSfWQoxy%25V1PaHrMfNEbn%25jf10s9Ol5NE4riIX4SscZwBQ2VrxRteajcZ857Mn6fRUgChOYQ5GvloIZFgp2XHL1xv6jQ%25JTZ2YDafu3bjmqn1SdiDyLOEVtpqTJIsN8WL3uBr0UMJdSeZ4P4uzVMRPcySkNh5bryVA0og9ZqNF4HviR00Kc%252wdd4WxfjtOccP81D7cOxbUEqUYV89GsLGSNUiprJpM%25Gw6Zu6QeptYRSYN167m5VmtoYCygNXs2mlDHEUexEamX9kuzHdU04cShtjBTc6o1Pw56"
  },
  {
    "brand": "BMW",
    "modelSeries": "5",
    "title" : "BMW 5 SERIES",
    "imageUrl": "COSY-EU-100-7331gPd9TZ7d5yKlHS9TUz6LlEJeiPlmpgpnJc0WHvQFVc5eqKJ4uQ7bswTlu1Q53EjAxKayVzbYCjVhuLB9u4RKwcJehoIjAiLLyTBzchtN9weqKNuL7TVswTsXlparxlTGix2xdxkVw178zi5AtECUkdb27slGAzOECrXpFknWlZQ6KAqSXRaYWFZAQ5nmPKIFagOybfKjnvIT91WpO2B3iEXMIjedwsQNBDMztrDdeqhk7ZSLMLoACe1chJHFlMIxou%25KXhn8HSfWQoO0%25V1PaHqffNEbn%25xd10s9OfCTE4riI15ascZwBE4irxRtesccZ857MrufRUgChZSR5GvloIWggp2XHByuv6jQ%25eT62YDafM3vjmqn1hv8DyLOEV%25GqTJIsNR6L3uBr0A7JdSeZ4lXuzVMRcXySkNh5xPDVA0og8xaNF4HvUmt0Kc%252GyF4WxfjtOccPrneSlJeicbz6gpnGSDqLvQFsC8L0aB3A%250"
  },
  {
    "brand": "BMW",
    "modelSeries": "6",
    "title" : "BMW 6 SERIES",
    "imageUrl": "COSY-EU-100-7331gPd9TZ7d5yKlHS9TUz6LlEJeiZllbgpnJc0WHvQFVc5eqKJ4uQ7bswTlu1Q53EjAxKayVzbYCjVhuLB9u4RKwcJehoIjAiLLyTBzchtNfpeqKNuL7TVswTsXlparxlTGix2xd9R1w178z3jetECUkdn17slGAzjJCrXpFksilZQ6KAnkXRaYWFObQ5nmPKIQagOybfMBnvIT91WpO26JdSeZGjbuzVMRpKkSkOPo90yW6fbHuscZwBEhWrxRtesQzZ857MrxxRUgChZSR5GvloIGcgp2XHBPvv6t3azDxVBadngMLoAC1wvhJ30Kc%252Gnb4WxfjpiOcPhtECUkaSH7s4gp2XHLNGv6jQ%25dxx2YIPwZAswT2mxuhJPLZf1EoKGOkDEgWi4UXg"
  },
  {
    "brand": "BMW",
    "modelSeries": "7",
    "title" : "BMW 7 SERIES",
    "imageUrl": "COSY-EU-100-7331gPd9TZ7d5yKlHS9TUz6LlEJeiXlMugpnJc0WHvQFVc5eqKJ4uQ7bswTlu1Q53EjAxKayVzbYCjVhuLB9u4RKwcJehoIjAiLLyTBzchtN5geqKNuL7TVswTsOBp5rxlTGix2xdTCIw178zB9dtECUkeih7slGAtnUCrXpF7PDlZQ6KCvnXRaYWljKQ5nmPXRYagOybADgnvIT9FQ8O2B3ioJOIjedwHsNBDF86Q0l6JBqR%25bH8oQt7C9cvANMCOxprg3O"
  },
  {
    "brand": "BMW",
    "modelSeries": "8",
    "title" : "BMW 8 SERIES",
    "imageUrl": "COSY-EU-100-7331gPd9TZ7d5yKlHS9TUz6LlEJeiLXLEgpnJc0WHvQFVc5eqKJ4uQ7bswTlu1Q53EjAxKayVzbYCjVhuLB9u4RKwcJehoIjAiLLyTBzchtNDQeqKNuL7TVswTsOVRArxlTGix2xdxbqw178z8vitECUkdEo7slGAzWECrXpFknWlZQ6KAqSXRaYWFOAQ5nmPKI9agOybfMBnvIT91leO2B3iEXVIjedwsnRBDMztrOreqhk7BnDMLoACe82hJHFlM0Cou%25KXhoJHSfWQose%25V1Pa7RSfNEbnCBr10s9Ol89E4riIXUqscZwBQ1IrxRte2oNZ857Mj61RUgChDYF5GvloqmWgp2XHLbxv6jQ%25JL92YDafu4Ajmqn1ScIDyLOEVFVqTJIsFfpL3uBrK1JJdSeZWYYuzVMRPlSSkNh5NxDVA0og0jqNF4Hv4aB0Kc%252cwQ4WxfjxqMcP81D8WOxbUEqUPy89RIhNQSht8iAm2YI6NLJujnWZXGucOMzK1c"
  },
  {
    "brand": "BMW",
    "modelSeries": "X1",
    "title" : "BMW X1",
    "imageUrl": "COSY-EU-100-7331jVukeZ7dOVsnvzjSD79F%25P6wDo7sYQ4LxDCpLXjNYPoxDZJgeYwMA8CYFtQ53EjAxKayVzbYCjVhuLB9u4RKwcJehoIjAiLLyTBzchtN5JeqKNuL7TVswTsyVA7rxlTGix2xdtXKw178z53utECUkgmS7slGAGVdCrXpFpNilZQ6K67VXRaYWYFRQ5nmPK5EagOybWh5nvIT9PS4O2B3ibvxIjeuznMRr0sSkNh5Z4hVA0ogRcFNF4HvmUx0Kc%252yu84WxfjTi7cP81D3svxbUEqcYp89GsLxjQUiprJ849Gw6ZuUwWptYRSGtc67magGybQwnnvIT9atpO2B3in8vIjedwOEXBDMztIcgeqhk7BS0MLoACefMhJHFlM1mou%25KXwrJHSfWQtOE%25V1Pa7cPfNEbnCxN10sUi%25rJ2CAGw6Zuj8KptYRS3LP67m5Vd49YCygNz%25imlTv0knGyX324AOfTQdjcFIE3azDxKrCdnkq8WZRzOALUPWukIFJGbfrABKHSzWQrvU%25V1Pa8TffNEbnEBM10s9OsdFE4riIrnascZwBZ6drxRteRhyZ857M5obRUgChg8F5Gvlo6qEgp2XHYrvv6jQ%25mp22YDafuo8jmqn1SrGDyLOEVwpqTMiCgWRCzq3GN%25ViSgsrZfbYeKJZj9786nj"
  },
  {
    "brand": "BMW",
    "modelSeries": "X2",
    "title" : "BMW X2",
    "imageUrl": "COSY-EU-100-2545xM4RIyFnbm9Mb3AgyyIJrjG0suyJRBODlsrjGpuaprQbhSIqppglBgHsjJl384MlficYiGHqoQxYLW7%25f3tiJ0PCJirQbLDWcQW7%251uSG9qoQh47wMvcYi9NGbFMb3islBglUUuJcRScHJzuMbnMdQzdyJGy58gArQ%25r9REUW8zWubG4qogqaJNQl3ilUQtzcRScH8ZuMbnMdoPHyJGy5BoorQ%25r9YEaW8zWunJjqogqaGQFl3ilU%2545cRScHzdBMbnMdg7oyJGy5iKErQ%25r9SQ5W8zWuKUUqogqaDjzl3ilUCzgcRScH4g7MbnMdeoSyJGy5m3XrQ%25r9sibW8zWuoD%25qogqa34Fl3ilURQzcRScHb8UMbnMdJozyJGy5Q32rQ%25r99aGW8zWuuRHqogqaab4l3ilUUb5cRScHHdmMbnMddAdyJGy57u3rQ%25r90KRW8zWuBa4qogqaYSQl3ilUE0mcRScHFNHMbnMdQfYyJR0aWAyFnhiExyJR5GlJirjGNY3QcNQBUJ1"
  },
  {
    "brand": "BMW",
    "modelSeries": "X3",
    "title" : "BMW X3",
    "imageUrl": "COSY-EU-100-2545xM4RIyFnbm9Mb3AgyyIJrjG0suyJRBODlsrjGpuaprQbhSIqppglBgoRnIl384MlficYiGHqoQxYLW7%25f3tiJ0PCJirQbLDWcQW7%251uRCHqoQh47wMvcYi9SDBJMb3islBglUQ3FcRScHzGlMbnMdQgayJGy53uKrQ%25r9RbFW8zWubDCqogqaJh5l3ilUQTqcRScH8ZAMbnMd09RyJGy5BoorQ%25r9YbJW8zWuEb2qogqaFSQl3ilU%25docRScHzo9MbnMdg3YyJGy5iRBrQ%25r9SEUW8zWunFbqogqaGCel3ilU%25bQcRScHzJmMbnMdg7oyJGy5i0BrQ%25r9SDFW8zWunC%25qogqaGJOl3ilU%25D8cRScHzD9MbnMdgCEyJGy5muBrQ%25r9sEGW8zWuKbGqogqaDJ6l3ilUCQDcRScH486MbnMdezJyJGy5QoarQ%25r98BYW8zWuobGqogqa3Jnl3ilUR%250cRScHbRfMbnMdJCYyJGy5Q4BrQ%25r99uDW8JYHltW7%25ZnjTW8JuzM8nq0z6Fboy6oEd82"
  },
  {
    "brand": "BMW",
    "modelSeries": "X4",
    "title" : "BMW X4",
    "imageUrl": "COSY-EU-100-7331jVukeZ7dLVsnvzjSD79F%25P6wDo7sYQ4LxDCpLXjNYPoxDZJgeYwMA8PYCqQ53EjAxKayVzbYCjVhuLB9u4RKwcJehoIjAiLLyTBzchtNf6eqKNuL7TVswTsyVJUrxlTGix2xdxCew178zi5MtECUkUS37slGAzEdCrXpFkaZlZQ6KAneXRaYWHBOQ5nmP%25F2agOybfCBnvIT91J4O2B3iEzMIjedwsk8BDMztIa5eqhk7Bn4MLoACeOqhJHFlMJ2ou%25KXhuIHSfWQoOz%25V1PaHvefNEbn%252V10s9Ofm6E4riI1ySscZwBEGArxRtesQzZ857MruZRUgChOD85GvloIGcgp2XHB1Dv6jQ%25eTQ2YDafM3Vjmqn1hdmDyLOEo2UqTJIsNfpL3uBr08TJdSeZ4FeuzVMRcKlSkNh5xQiVA0og8xaNF4HvUmt0Kc%252GyF4WxfjtpkcP81D7vkxbUEqCB889GsLlZHUi5Bo0aVo7UwFyjmBY0JuSDOPRQpSxIhkWEx"
  },
  {
    "brand": "BMW",
    "modelSeries": "X5",
    "title" : "BMW X5",
    "imageUrl": "COSY-EU-100-2545xM4RIyFnbm9Mb3AgyyIJrjG0suyJRBODlsrjGpuaprQbhSIqppglBgbR9Kl384MlficYiGHqoQxYLW7%25f3tiJ0PCJirQbLDWcQW7%251uSGVqoQh47wMvcYi9hQCHMb3islBglUbOdcRScHz%255MbnMdo13yJGy536nrQ%25r9RXHW8zWubxKqogqaJh0l3ilUj8ocRScH7oBMbnMd0oiyJGy5B31rQ%25r9Yi8W8zWuE5jqogqaGHHl3ilU%25J0cRScHzQ5MbnMdg80yJGy5iRErQ%25r9SbYW8zWunGJqogqaGC7l3ilU%254JcRScHz83MbnMdgH2yJGy5ieRrQ%25r9SmHW8zWunfCqogqaGo5l3ilU%253wcRScHzN5MbnMdeB8yJGy5msbrQ%25r9sRmW8zWuKSVqogqa3Gzl3ilURaQcRScHbR2MbnMdJ13yJGy5Q4ErQ%25r98eYW8zWuujMqoQEdcNq0zPG7ZqoQagyoGlBg1jJ3r13F5ot"
  },
  {
    "brand": "BMW",
    "modelSeries": "X6",
    "title" : "BMW X6",
    "imageUrl": "COSY-EU-100-7331jVukeZ7dMVsnvzjSD79F%25P6eDo7sYQ4LxDCpLXjNYPoxDZJgeYwMA8PYFyQ53MEf0PayVHEXABNM1hypctUDYhiyXqwOSsNTjHJcv6OHKhekxh1lNUEMAKWzOS8eejDkJEKG7E2ABN%25hepDHtUDt4SlS7sYDR8snsLspqUiprJ8R3Gw6ZuZ5eptYRSJOM67m5Vut9YCygNS3umlTv0VdcyX324NzETQdjc96k3azDxiYodnkq8wn4zOALUtoskIFJG7SWABKupCVRFeWS6AiuKMPVYFGeWhbNmKkOPo90yWAZbHi4TPFo9%25wc3bXjiftxd9Agw178ziFrtECUkw5O7slGAthYCrXpF7TulZQ6KC3kXRaYWlyQQ5nmPXBFagOybQePnvIT9agyO2B3iK7MIjedwWJjBDMztPOpeqhk7f6vMLoAC1NnhJHFlE1Wou%25KXs86HSfWQrvU%25V1PaZ2SfNEbnUXL10s9OGQEE4riIpuuscZwB6kUrxRteYCPZ857MmouRUQAP13%25P6RGNDIjA21hoHBzcXTgHrkWS4tr"
  },
  {
    "brand": "BMW",
    "modelSeries": "X7",
    "title" : "BMW X7",
    "imageUrl": "COSY-EU-100-7331jVukeZ7dMVsnvzjSD79F%25P6eDo7sYQ4LxDCpLXjNYPoxDZJgeYwMA8mYFKQ53MEf0PayVHEXABNM1hypctUDYhiyXqwOSsNTjHJcv6OHKhekxh1lNUEMAKWzOS8eejDkJEKG%25YAABN%25hepDHtUDt4S7I7sYDR8snsLDQIUiprJqYDGw6ZuLmVptYRSJyF67m5VKJEYCygNWUBmlTv0PGCyX324bO2TQdjc9IM3azDxiytdnkq8wcHzOALUtuckIFJGzbLABKupk81FeWS6AUuKMPVYFznWhbNmKkrPo90yWAhbHi4TPl29%25wc3bk5iftxd9Asw178ziZltECUkw5O7slGAtyJCrXpF7TzlZQ6KCmXXRaYWlIKQ5nmPXB1agOybQ5mnvIT9a1OO2B3iK2qIjedwWJjBDMztPOpeqhk7f6vMLoAC1NnhJHFlE1Wou%25KXsg8HSfWQrvu%25V1Pa8lqfNEbnUIj10s9OY%25EE4riI4U3scZwBw7drxRteIvhZ857MujNRUgChSXC5GvloRNagp2XH5aKv6jQ%25eT02YDafMg%25jmqn1S4YDyLOEA%25qqTJIsFppL3uBrK7kJdSeZGCtuzH7Qj9vQFukYcE470jR5gswTobVgLtXpyBL"
  },
  {
    "brand": "BMWi",
    "modelSeries": "i3",
    "title" : "BMW i3",
    "imageUrl": "COSY-EU-100-7331sBgrfZ7dQMw1paheLztUDIhiyXqaMnU4uWNRxnFUi591WmyGZI6aorNF1YLYrt3mkIVhZ7d2gmjHS91Zys8%25P6EaUM3fNwf5dIlXvQFvzC4M2YIqdRMA8oEX0GshFWPkIVlyQkc5DnTNK9mlD2ERgvtb7Gq3Mnd"
  },
  {
    "brand": "BMWi",
    "modelSeries": "i4",
    "title" : "BMW i4",
    "imageUrl": "COSY-EU-100-2545xM4RIyFnbm9Mb3AgyyIJrjG0suyJRBODlsrjGpuaprQbhSIqppglBguRJOl384MlficYiGHqoQxYLW7%25f3tiJ0PCJirQbLDWcQW7%251uRCRqoQh47wMvcYi9kQC5Mb3islBglUjaqcRScHzGoMbnMdomsyJGy5BoorQ%25r9YbJW8zWunJEqogqaGjdl3ilU%25e5cRScHzmBMbnMdgsSyJGy5mYnrQ%25r9snGW8zWuoD%25qogqa3EFl3ilUQI8cRSrQdr9RXHW8zWuEbrqogqaFk5l3ilU%25QpcRScHzDsMbnMdgCEyJGy5iL3rQ%25r9sRnW8zWuKbYqogqaDJKl3ilUCQIcRScH4%25oMbnMdezJyJGy5Q3SrQ%25r98R5W8zWuobuqogqa3JWl3ilURQGcRScHbziMbnMdJgAyJGy5QgFrQ%25r98QIW8zWuomjqogqaGjJl38F5MIlBgL%250Pl38Uir3%25cYiA7QRWARj93N"
  },
  {
    "brand": "BMWi",
    "modelSeries": "i8",
    "title" : "BMW i8",
    "imageUrl": "COSY-EU-100-73317K9wt0u4fXBh0SL3hSF2%25P61RO8m5fbiwWhc972wsP05iyD5NbH8lBOjT9cvaBP672lIXcVqAus0XzcPr4jGcVxEaRqfNwVKJZR19mspzb"
  },
  {
    "brand": "BMWi",
    "modelSeries": "iX",
    "title" : "BMW iX",
    "imageUrl": "COSY-EU-100-2545xM4RIyFnbm9Mb3AgyyIJrjG0suyJRBODlsrjGpua8rQbhSIqppglBgbRGal384MlficYiGHqoQxYLW7%25f3tiJ0PCJirQbLDWcQW7%251uSDUqoQh47wMvcYi9M%25bgMb3islBglUb6%25cRScH8Z4MbnMdoPEyJGy5BRbrQ%25r9YEUW8zWunUUqogqaGQQl3ilU%2540cRScHz8oMbnMdgHOyJGy5ieSrQ%25r9SQHW8zWun87qogqaGhqogqaDJKl3ilUCQIcRScH4%25fMbnMdJmSyJGy5QinrQ%25r985RW8zWuo86qogqa3s7l3ilURKdcRScH715MbnMdgC0yJGy5QJlrQ%25r98BYW8zWun9rqogqa3oyl3ilUj7mcRScH78gMbnMdezJyJGy5iRarQ%25r9SDGW8JYHltW7%25ZnjTW8JuzM8nq0z6Fboy6oEd82"
  },
  {
    "brand": "BMWi",
    "modelSeries": "iX3",
    "title" : "BMW iX3",
    "imageUrl": "COSY-EU-100-2545xM4RIyFnbm9Mb3AgyyIJrjG0suyJRBODlsrjGpuaprQbhSIqppglBgnRnKl384MlficYiGHqoQxYLW7%25f3tiJ0PCJirQbLDWcQW7%251unRRqoQh47wMvcYi9kQwCMb3islBglUQ80cRScH86oMbnMdoPayJGy53LSrQ%25r9YaJW8zWuERRqogqaFk%25l3ilUjn8cRScHz53MbnMdg8YyJGy5iRErQ%25r9SbYW8zWunFHqogqaGjJl3ilU%254mcRScHzJ8MbnMdgQsyJGy5i03rQ%25r9SBYW8zWunCHqogqaG47l3ilU%25egcRScHz8fMbnMdgCuyJGy5i4DrQ%25r9SejW8zWunxbqogqaDjzl3ilUC4ocRScH48gMbnMdeo2yJGy5m3erQ%25r9sibW8zWuKSoqogqa3J%25l3ilURJ5cRScHbj7MbnMdJoHyJGy5Q3grQ%25r98SFW8zWuo8Vqogqa3s7l3ilURKjcRScHbpzMbnMdd8uyJGy55oKrQ%25r99oUW8zWuuaCqogqaGS3l3ilUbTrcRoj9y6cYiXzBLcRoHSWRzMES208bq2b7uRI"
  },
  {
    "brand": "BMW",
    "modelSeries": "Z3",
    "title" : "BMW Z3",
    "imageUrl": "assets/cosy/BMW_Z3.jpg"
  },
  {
    "brand": "BMW",
    "modelSeries": "Z4",
    "title" : "BMW Z4",
    "imageUrl": "COSY-EU-100-2545xM4RIyFnbm9Mb3AgyyIJrjG0suyJRBODlsrjGpuaprQbhSIqppglBgm9CJl384MlficYiGHqoQxYLW7%25f3tiJ0PCJirQbLDWcQW7%251uSDRqoQh47wMvcYi9kQaaMb3islBglUb1ucRScHzG%25MbnMdQ99yJGy58o5rQ%25r9o3iW8zWu3JEqogqaRQel3ilUb7ocRScHJg5MbnMdQmoyJGy58sErQ%25r9oKDW8zWu3DUqogqaRRJl3ilUbb0cRScHJJiMbnMdo9YyJGy53oBrQ%25r9Rb8W8zWubG6qogqa3%25vl3ilUUJecRScHHJ9MbnMdo9YyJGy53oBrQ%25r9Rb8W8zWubG6qogqa3%25vl3ilUUJecRScHHJ9MbnMdoBuyJGy5B0urQ%25r9saGW8zWuKRHqogqaGJOl3ilUb80cRScHJoiMbnMdJiRyJGy5QsnrQ%25r98oJW8zWuo3jqogqa3UJl3ilURHJcRScHbd5MbnMdJsuyJGy57SMrQ%25r9SERW8zWuK3jqogqa3H5l3ilURdwcRScHbefMbnMdji1yJGy578UrQ%25r9R1HW8zWuoU4qogqa3bJl3ilURJgcRScHbQoMbnMdJ8JyJGy57sKrQ%25r9KnmW8zWub8KqogqaJ2zl3ilUQTUcRScH457MbnMdeiSyJGy55o3rQ%25r99bJW8zWuuRHqogqaaj4l3ilUU7UcRScHHd7MbnMdj3qyJGy5mYnrQ%25r90uAW8zWuoFKqogqaYCQl3ilUEbocRScHFJBMbnMdj5oyJGy5792rQ%25r90aAW8zWuBakqogqaGR4l3ilU%25e0cRScHe58MbnMdm3zyJGy5sSBrQ%25r9snYW8zWuDRhqogqa3Ubl3ilUbGUcRScHFe8Mb37ur1MESxgYXMb3dnqbgyFntBoJltJ0ab6"
  },
  {
    "brand": "BMW",
    "modelSeries": "Z8",
    "title" : "BMW Z8",
    "imageUrl": "assets/cosy/BMW_Z8.jpg"
  }
]

