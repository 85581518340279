<div class="popover-background" (click)="onOverlayClicked($event)">
  <div class="popover" (click)="onPopoverClicked($event)">
    <section class="popover-header">
      <div>
        <fd-icon class="close-button"
                 name="close_bold"
                 [size]="32"
                 (click)="close()"></fd-icon>
      </div>
      <div class="title fd-h3">
        <ng-content select="[title]"></ng-content>
      </div>
    </section>
    <section class="popover-body">
      <div class="label subtitle-1">
        <ng-content select="[label]"></ng-content>
      </div>
      <ng-container *ngIf="componentType; else contentTemplate">
        <ng-container #bodyContent></ng-container>
      </ng-container>
      <ng-template #contentTemplate>
        <ng-content select="[bodyContent]"></ng-content>
      </ng-template>
    </section>
    <section class="popover-actions ">
      <span class="btn_display"><ng-content select="[actionButtons]"></ng-content></span>
    </section>
  </div>
</div>
