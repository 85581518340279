import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {shareReplay} from "rxjs/operators";
import {environment} from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class CardsDocumentsService {
  private baseUrl = environment.SERVER_API_URL + 'api/cards-documents';

  constructor(private http: HttpClient) {
  }

  getCardsDocuments(modelSeries: string,
                    sop: string,
                    brand: string = 'BMW',
                    documentType: string = "Betriebsanleitung",
                    language: string = "en-GB"): Observable<any[]> {

    const params = new HttpParams()
      .set('Brand.in', brand)
      .set('documentType.in', documentType)
      .set('language.in', language)
      .set('modelSeries.in', modelSeries)
      .set('sop.in', sop);

    return this.http.get<any[]>(this.baseUrl, {params: params}).pipe(shareReplay(1));
  }

}
