import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {DocumentsService} from "../../core/services/documents.service";
import {EMPTY} from "rxjs";
import {mergeMap} from "rxjs/operators";

@Component({
  selector: 'app-content-page',
  templateUrl: './content-page.component.html',
  styleUrls: ['./content-page.component.scss']
})
export class ContentPageComponent implements OnInit {



  constructor(private _Activatedroute: ActivatedRoute,
              private documentsService: DocumentsService) {
  }

  ngOnInit() {
    this._Activatedroute.paramMap.pipe(
      mergeMap(params => {
        if (!params) return EMPTY;
        return this.documentsService.getDocumentById(params.get('uuid'));
      })).subscribe((pdf) => {
      if (!pdf) return;
    });
  }

}
